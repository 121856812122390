<template>
  <PreviewContract
    v-if="contractPreview"
    :contract="contract"
    @close="contractPreview = false"
  />
  <transition name="modal" v-else>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div @click="$emit('close')" class="icon">
              <ModalCloseIcon :accent="false" />
            </div>
          </div>

          <div class="modal-body">
            <div class="text">
              <div v-html="inducement.contract_text"></div>
              <div class="signatures" v-if="inducement.signature">
                <div>
                  <span
                    class="signature-value"
                    :style="{
                      fontFamily: `${inducement.signature.value.font}, cursive`
                    }"
                  >
                    {{ inducement.signature.value.name }}
                  </span>
                  <span class="party-name">
                    {{
                      inducement.signature.user.first_name +
                        " " +
                        inducement.signature.user.last_name
                    }}
                  </span>
                </div>
              </div>
            </div>

            <template v-if="!inducement.signature">
              <div class="details">
                <div class="name">
                  <span>Full Name:</span>
                  <input
                    type="text"
                    v-model="name"
                    class="text-input"
                    placeholder="Enter your full name"
                  />
                </div>
                <div class="font">
                  <span>Change Font:</span>
                  <CustomSelect
                    :options="fonts"
                    default="Srisakdi"
                    @setValue="value => (font = value)"
                    class="font-selector"
                    noMargin
                  />
                </div>
              </div>
              <div class="signature">
                <div class="output">
                  <span class="name" :style="signatureStyle">
                    {{ name }}
                  </span>
                </div>
              </div>
              <span class="info">
                By selecting Accept and Sign, I agree that the signature will be
                the electronic representation of my signature for all purposes
                when I (or my agent) use them on documents, including legally
                binding contracts - just the same as a pen-and-paper signature
                or initial.
              </span>
            </template>
          </div>

          <div class="modal-footer">
            <div class="buttons">
              <button @click="$emit('close')" class="primary outline">
                Close
              </button>
              <button
                v-if="!inducement.signature"
                :disabled="!name || submitting"
                @click="signContract"
                class="primary"
              >
                {{ submitting ? "Submitting..." : "Accept and Sign" }}
              </button>
              <button v-else @click="print" class="primary outline">
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import PreviewContract from "@/components/modals/PreviewContract.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import CustomSelect from "@/components/Select.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import SIGN_INDUCEMENT_CONTRACT from "@/api/mutations/SIGN_INDUCEMENT_CONTRACT.gql";

export default {
  name: "InducementContract",
  components: {
    ModalCloseIcon,
    CustomSelect,
    PreviewContract
  },
  props: {
    deal: Object,
    inducement: Object
  },
  data() {
    return {
      name: "",
      font: "Srisakdi",
      fonts: [
        { value: "Srisakdi", label: "Srisakdi" },
        { value: "Sacramento", label: "Sacramento" }
      ],
      submitting: false,
      contractPreview: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    },
    signatureStyle() {
      const { font } = this;
      return {
        fontFamily: `${font}, cursive`
      };
    }
  },
  methods: {
    async signContract() {
      this.submitting = true;

      const {
        name,
        font,
        inducement: { contract_id },
        user: { id: user_id },
        deal: {
          party,
          myArtist: { id: fromID }
        }
      } = this;

      const { data } = await axios.get(
        "https://nvoko-hasura-remote-git-contract-signed-nvoko.vercel.app/api/info"
      );

      const signature = {
        value: { name, font },
        type: "inducement",
        contract_id,
        artist_id: fromID,
        user_id,
        party,
        ...data
      };

      this.$apollo.mutate({
        mutation: SIGN_INDUCEMENT_CONTRACT,
        variables: { signature },
        update: (store, { data: { insert_contract_signatures_one } }) => {
          const songsObject = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = songsObject.songs.findIndex(
            el => el.id === this.deal.song_id
          );
          const index = songsObject.songs[
            song
          ].splits[0].split_details.findIndex(
            el => el.deal_id === this.deal.id
          );

          songsObject.songs[song].splits[0].split_details[
            index
          ].deal.contracts[0].signatures.push(insert_contract_signatures_one);

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data: songsObject
          });

          this.submitting = false;
          this.$emit("close");
        }
      });
    },
    print() {
      const contractURL = this.inducement.contract_text;
      window.open(contractURL, "_blank");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Srisakdi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ojuju&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");

.modal-container {
  width: 755px;
  height: fit-content;
  max-height: calc(100vh - 40px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 16px;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.modal-body {
  padding: 0 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .text {
    flex: 1;
    width: 100%;
    /* height: calc(100vh - 615px); */
    overflow-y: scroll;
    margin-bottom: 1rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    .signatures {
      margin: 1rem;
      display: flex;
      justify-content: space-around;
      div {
        display: flex;
        flex-direction: column;
        .signature-value {
          font-size: 2rem;
        }
      }
    }
  }
  .cant-sign {
    color: $text;
    padding: 0.5rem 0.2rem;
  }
  .details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
    .name {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
      input {
        height: 40px;
        width: 13rem;
        color: #3b3b3b;
      }
    }
    .font {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
      .font-selector {
        width: 11rem;
      }
    }
  }
  .signature {
    height: 8rem;
    background: $gray;
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .output {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-bottom: 1px solid $text-additional;
      margin: 0 24px;
      margin-bottom: 16px;
      .name {
        font-size: 45px;
        height: 65px;
        text-align: center;
        font-style: italic;
        letter-spacing: -0.22px;
        color: $text;
      }
    }
  }
  .info {
    @include details-light();
    width: 616px;
    margin-bottom: 1.5rem;
  }
  .invalid-split {
    @include details-light();
    color: red;
    width: 616px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3px;
  }
}

.modal-footer {
  padding: 7px 40px 20px;
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>

<template>
  <div class="right-panel">
    <Loader v-if="$apollo.queries.deal.loading && !refetching" size="50" />
    <div v-else-if="!deal" />
    <div v-else class="panel-container">
      <div class="panel-header">
        <div class="deal-pending" v-if="deal.status === 'pending'">
          <div class="image" v-if="deal.artist.avatar_url">
            <img :src="deal.artist.avatar_url" />
          </div>
          <div class="initials" v-else>
            {{ deal.artist.name | initials }}
          </div>
          <p class="name">{{ deal.artist.name }}</p>
          <p class="subtitle">{{ deal.subtitle }}</p>
          <div class="preference" v-if="deal.earnsBy">
            <p>{{ deal.earnsBy.symbols }}</p>
            <p>{{ deal.earnsBy.text }}</p>
          </div>
          <div class="buttons">
            <div v-if="deal.party === 'guest'">
              <button
                class="primary outline"
                @click="cancelRequest"
                :disabled="cancellingRequest"
              >
                {{ cancellingRequest ? "Submitting..." : "Not Interested" }}
              </button>
              <button @click="showModal = 'offer-editor'" class="primary">
                Make Offer
              </button>
            </div>
            <div v-else>
              <button
                class="primary"
                @click="cancelRequest"
                :disabled="cancellingRequest"
              >
                {{ cancellingRequest ? "Submitting..." : "Cancel Request" }}
              </button>
            </div>
          </div>
        </div>
        <div class="deal-offer-sent" v-if="deal.status === 'offer-sent'">
          <div class="buttons">
            <button @click="showModal = 'offer-editor'" class="primary">
              Revise Offer
            </button>
            <button
              @click="acceptOffer"
              class="primary"
              v-if="deal.party === 'host'"
              :disabled="acceptingOffer.status"
            >
              {{ acceptingOffer.buttonText }}
            </button>
          </div>
        </div>
        <div class="deal-offer-sent" v-if="deal.status === 'funding-pending'">
          <div class="buttons">
            <button
              v-if="deal.party === 'host'"
              @click="showModal = 'project-funding'"
              class="primary proceed-payment"
            >
              Proceed to payment
            </button>
            <p v-else>
              <i>Waiting for {{ deal.artist.name }} to fund the project...</i>
            </p>
          </div>
        </div>
        <div class="deal-offer-sent" v-if="deal.status === 'contract-pending'">
          <div class="buttons">
            <button @click="showModal = 'contract-editor'" class="primary">
              Create Contract
            </button>
          </div>
        </div>
        <div
          class="deal-offer-sent"
          v-if="deal.status === 'contract-answers-waiting'"
        >
          <div class="buttons">
            <p>
              <i
                >Waiting for {{ deal.artist.name }} to answer contract
                questions...</i
              >
            </p>
          </div>
        </div>
        <div
          class="deal-offer-sent"
          v-if="deal.status.includes('contract-created')"
        >
          <div class="buttons">
            <button @click="showModal = 'contract-review'" class="primary">
              Review Contract
            </button>
            <button @click="showModal = 'contract-signing'" class="primary">
              Sign Contract
            </button>
          </div>
        </div>
        <div
          class="deal-offer-sent"
          v-if="deal.status === 'contract-party-signed'"
        >
          <div class="buttons">
            <p>
              <i>Waiting for {{ deal.artist.name }} to sign the contract...</i>
            </p>
          </div>
        </div>
        <div
          class="deal-offer-sent"
          v-if="['cancelled', 'not-interested'].includes(deal.status)"
        >
          <div class="buttons">
            <p>
              <strong>This feature request is closed</strong>
            </p>
          </div>
        </div>
        <div class="splits" v-if="deal.party === 'host'">
          <span @click="showModal = 'splits'">Propose a split</span>
        </div>
      </div>
      <div class="divider" />
      <div class="chat" id="chat">
        <div class="messages">
          <div
            class="item"
            v-for="({
              message,
              file,
              name,
              avatarUrl,
              systemMessage,
              date,
              time
            },
            i) in deal.messages"
            :key="i"
          >
            <div class="date">{{ date }}</div>
            <div class="system-message" v-if="systemMessage">
              <p class="description">{{ message }}</p>
            </div>
            <div class="message" v-else>
              <div class="left">
                <div class="image">
                  <img :src="avatarUrl" />
                </div>
              </div>
              <div class="middle">
                <p class="name">{{ name }}</p>
                <p class="text" v-if="!message && file">
                  <i class="shared-file">Shared a file</i>
                </p>
                <p class="text" v-else>{{ message }}</p>
                <div v-if="file">
                  <div class="track" v-if="file.type === 'audio'">
                    <AudioPlayer :download="true" :file="file" v-if="file" />
                  </div>
                  <div v-else-if="file.type === 'image'">
                    <ImagePreview :file="file" />
                  </div>
                  <div v-else>
                    <FileLink :file="file" />
                  </div>
                </div>
              </div>
              <div class="right">
                <span class="time">{{ time }}</span>
              </div>
            </div>
          </div>
          <div class="item sending-message" v-if="sendingMessage.status">
            <div class="date">{{ sendingMessage.date }}</div>
            <div class="message">
              <div class="left">
                <div class="image">
                  <img :src="sendingMessage.avatarUrl" />
                </div>
              </div>
              <div class="middle">
                <p class="name">{{ sendingMessage.name }}</p>
                <p class="text">{{ sendingMessage.message }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="input">
          <div class="texta">
            <textarea placeholder="Type your message..." v-model="newMessage" />
            <div class="buttons">
              <button
                class="attachment"
                :disabled="!!file"
                :class="{ 'upload-active': showUploadForm }"
                @click="showUploadForm = !showUploadForm"
              >
                <img src="../assets/icons/Attach-File.svg" />
              </button>
              <button
                :disabled="(!newMessage && !file) || (showUploadForm && !file)"
                class="send"
                @click="sendMessage"
              >
                <ArrowRightIcon :accent="false" />
              </button>
            </div>
          </div>
        </div>
        <FileUpload v-if="showUploadForm" v-model="file" />
      </div>
    </div>
    <OfferEditor
      :deal="deal"
      v-if="showModal === 'offer-editor'"
      @close="showModal = ''"
    />
    <ProjectFunding
      :deal="deal"
      v-if="showModal === 'project-funding'"
      @close="showModal = ''"
    />
    <ContractEditor
      :deal="deal"
      v-if="showModal === 'contract-editor'"
      @close="showModal = ''"
    />
    <ContractReview
      :deal="deal"
      v-if="showModal === 'contract-review'"
      @close="showModal = ''"
    />
    <ContractSigning
      :deal="deal"
      v-if="showModal === 'contract-signing'"
      @close="showModal = ''"
    />
    <Splits
      :deal="deal"
      v-if="showModal === 'splits'"
      @close="showModal = ''"
    />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import FileUpload from "@/components/FileUpload.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import ArrowRightIcon from "@/assets/icons/Arrow-right.vue";
import OfferEditor from "@/components/modals/OfferEditor.vue";
import ProjectFunding from "@/components/modals/ProjectFunding.vue";
import ContractEditor from "@/components/modals/ContractEditor.vue";
import ContractReview from "@/components/modals/ContractReview.vue";
import ContractSigning from "@/components/modals/ContractSigningOld.vue";
import Splits from "@/components/modals/Splits.vue";
import ImagePreview from "@/components/ImagePreview.vue";
import FileLink from "@/components/FileLink.vue";

import GET_DEAL from "@/api/queries/GET_DEAL.gql";
import GET_MESSAGES from "@/api/subscriptions/GET_MESSAGES.gql";
import SEND_MESSAGE from "@/api/mutations/SEND_MESSAGE.gql";
import CHANGE_DEAL_STATUS from "@/api/mutations/CHANGE_DEAL_STATUS.gql";

export default {
  name: "DealPreview",
  components: {
    Loader,
    FileUpload,
    AudioPlayer,
    ArrowRightIcon,
    OfferEditor,
    ProjectFunding,
    ContractEditor,
    ContractReview,
    ContractSigning,
    Splits,
    ImagePreview,
    FileLink
  },
  data() {
    return {
      skip: true,
      showModal: "",
      newMessage: "",
      file: "",
      showUploadForm: false,
      cancellingRequest: false,
      sendingMessage: {
        status: false
      },
      acceptingOffer: {
        status: false,
        buttonText: "Accept Offer"
      },
      refetching: false
    };
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    getDayAndTime(timestamp, prev) {
      const createdAt = new Date(timestamp);
      const date = createdAt.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
      const time = createdAt.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit"
      });

      return {
        date: date !== prev ? date : null,
        time
      };
    },
    sendMessage() {
      const { deal, newMessage, file } = this;
      if ((newMessage || file) && deal) {
        const { date } = this.getDayAndTime(new Date(), this.currentLoopDate);

        this.newMessage = "";
        this.showUploadForm = false;
        this.file = "";

        const variables = {
          artist_id_from: deal.myArtist.id,
          artist_id_to: deal.artist.id,
          deal_id: deal.id,
          message: newMessage
        };

        if (file) variables.file_id = file;

        this.sendingMessage = {
          status: true,
          date,
          name: deal.myArtist.name,
          avatarUrl: deal.myArtist.avatar_url,
          message: newMessage,
          file_id: file
        };

        this.$apollo.mutate({
          mutation: SEND_MESSAGE,
          variables,
          update: (store, { data: { insert_messages_one } }) => {
            const data = store.readQuery({
              query: GET_DEAL,
              variables: {
                id: this.$route.query.view
              }
            });

            data.deals_by_pk.messages = [
              ...data.deals_by_pk.messages,
              insert_messages_one
            ];

            this.sendingMessage = { status: false };

            store.writeQuery({
              query: GET_DEAL,
              data
            });
          }
        });
      }
    },
    acceptOffer() {
      this.acceptingOffer = {
        status: true,
        buttonText: "Submitting..."
      };

      const {
        id,
        myArtist: { id: fromID, name: myArtistName },
        artist: { id: toID },
        fee
      } = this.deal;

      const system_message = {
        recipient: `${myArtistName} has accepted the offer`,
        sender: "You have accepted the offer"
      };

      const status = fee ? "funding-pending" : "contract-pending";

      this.$apollo.mutate({
        mutation: CHANGE_DEAL_STATUS,
        variables: {
          id,
          toID,
          fromID,
          message: system_message,
          status
        },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_DEAL,
            variables: {
              id: this.$route.query.view
            }
          });

          data.deals_by_pk = { ...data.deals_by_pk, ...update_deals_by_pk };

          this.acceptingOffer = { status: false };

          store.writeQuery({
            query: GET_DEAL,
            data
          });
        }
      });
    },
    checkDealChanged(old, updated) {
      const newMessages = updated.slice(old.length);
      if (newMessages.some(el => el.system_message)) {
        this.refetching = true;
        this.$apollo.queries.deal.refetch();
      }
    },
    cancelRequest() {
      this.cancellingRequest = true;

      const {
        id,
        myArtist: { id: fromID, name: myArtistName },
        artist: { id: toID },
        party
      } = this.deal;

      const system_message =
        party === "host"
          ? {
              recipient: `${myArtistName} has cancelled the request`,
              sender: "You have cancelled the request"
            }
          : {
              recipient: `${myArtistName} has rejected the feature request`,
              sender: "You have rejected the feature request"
            };

      const status = party === "host" ? "cancelled" : "not-interested";

      this.$apollo.mutate({
        mutation: CHANGE_DEAL_STATUS,
        variables: {
          id,
          toID,
          fromID,
          message: system_message,
          status
        },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_DEAL,
            variables: {
              id: this.$route.query.view
            }
          });

          data.deals_by_pk = { ...data.deals_by_pk, ...update_deals_by_pk };

          this.cancellingRequest = false;

          store.writeQuery({
            query: GET_DEAL,
            data
          });
        }
      });
    }
  },
  mounted() {
    this.skip = !this.$route.query.view;
  },
  updated() {
    const container = this.$el.querySelector("#chat");
    if (container) container.scrollTop = container.scrollHeight;
  },
  watch: {
    "$route.query.view"(id) {
      this.skip = !id;
    },
    sendingMessage({ status }) {
      if (status) {
        const container = this.$el.querySelector("#chat");
        container.scrollTop = container.scrollHeight;
      }
    }
  },
  apollo: {
    deal: {
      query: GET_DEAL,
      variables() {
        return { id: this.$route.query.view };
      },
      update({ deals_by_pk }) {
        this.refetching = false;

        const {
          id,
          status,
          artistFrom,
          artistTo,
          deal_type,
          track_title,
          file_id_track,
          description,
          messages,
          created_at,
          fee,
          recording_royalty_minimum,
          songwriting_royalty_minimum,
          services,
          contracts,
          song_id
        } = deals_by_pk;
        const isFromMe = !!(
          artistFrom.artist_users[0].user_id ===
          this.$store.getters["account/getUserId"]
        );
        const artist = isFromMe ? artistTo : artistFrom;
        const myArtist = isFromMe ? artistFrom : artistTo;

        const party = isFromMe ? "host" : "guest";

        const subtitle = isFromMe
          ? `you have requested a feature with ${artist.name}`
          : "has requested a feature from you";

        const earning = artist.artist_preferences[0].earning_preference || {};
        let earnsBy;
        if (earning.royalties && earning.fee) {
          earnsBy = {
            symbols: "$ + %",
            text: "Fees + Royalties"
          };
        } else if (earning.royalties || earning.fee) {
          const byFees = earning.fee;
          earnsBy = {
            symbols: byFees ? "$" : "%",
            text: byFees ? "Flat Fee" : "Only Royalties"
          };
        } else {
          earnsBy = null;
        }

        const firstMessage = () => {
          const { name, avatar_url: avatarUrl } = artist;
          const { date, time } = this.getDayAndTime(
            created_at,
            this.currentLoopDate
          );
          this.currentLoopDate = date || this.currentLoopDate;

          return {
            name,
            avatarUrl,
            message: description,
            file: file_id_track
              ? {
                  id: file_id_track,
                  name: track_title,
                  type: "audio"
                }
              : null,
            systemMessage: false,
            date,
            time
          };
        };

        const formattedMessages = messages.flatMap(
          (
            {
              artistFrom: { name, avatar_url: avatarUrl, artist_users },
              message: chatMessage,
              system_message,
              file,
              created_at: c_a
            },
            i
          ) => {
            const isMessageFromMe = !!(
              artist_users[0].user_id ===
              this.$store.getters["account/getUserId"]
            );
            let message;
            let systemMessage = false;
            if (system_message) {
              message = isMessageFromMe
                ? system_message.sender
                : system_message.recipient;
              systemMessage = true;
            } else {
              message = chatMessage;
            }

            const { date, time } = this.getDayAndTime(
              c_a,
              this.currentLoopDate
            );
            this.currentLoopDate = date || this.currentLoopDate;

            const messages = [
              { name, avatarUrl, message, file, systemMessage, date, time }
            ];

            if (!i && (description || file_id_track))
              messages.push(firstMessage());

            return messages;
          }
        );

        let newStatus;
        if (["contract", "answered"].every(el => status.includes(el))) {
          newStatus = status.includes(party)
            ? "contract-answers-waiting"
            : "contract-pending";
        }
        if (["contract", "signed"].every(el => status.includes(el))) {
          newStatus = status.includes(party)
            ? "contract-party-signed"
            : "contract-created-sign-pending";
        }

        if (!formattedMessages.length && (description || file_id_track))
          formattedMessages.push(firstMessage());

        return {
          id,
          status: newStatus || status,
          party,
          artist,
          myArtist,
          subtitle,
          earnsBy,
          messages: formattedMessages,
          fee,
          recording_royalty_minimum,
          songwriting_royalty_minimum,
          services,
          contract: contracts[0],
          info: {
            song_id,
            deal_type,
            description,
            track_title,
            file_id_track
          }
        };
      },
      skip() {
        return this.skip;
      },
      subscribeToMore: {
        document: GET_MESSAGES,
        variables() {
          return { deal: this.$route.query.view };
        },
        updateQuery(
          previousResult,
          {
            subscriptionData: {
              data: { messages }
            }
          }
        ) {
          const result = previousResult;
          this.checkDealChanged(result.deals_by_pk.messages, messages);
          result.deals_by_pk.messages = messages;
          return result;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background: $bg;

  .panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .panel-header {
      .deal-pending {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 0 16px;
        margin-bottom: 0;

        .image {
          img {
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
        }

        .initials {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100px;
          width: 100px;
          font-family: sans-serif;
          font-size: 45px;
          border-radius: 50%;
          background-color: $black;
          border: 1px solid $accent;
          color: $white;
        }

        .name {
          font-size: 16px;
          font-weight: 500;
          color: $text;
          margin-top: 16px;
        }

        .subtitle {
          @include details-light();
          font-weight: 600;
          margin: 10px 0;
        }

        .preference {
          text-align: center;

          p {
            @include details-light();
            margin-top: 10px;
          }
        }

        .buttons {
          margin-top: 24px;

          button {
            width: 136px;
            height: 44px;
            margin: 0 16px;
            padding: 13px 0;
          }
        }
      }

      .deal-offer-sent {
        .buttons {
          margin: 1rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          button {
            width: 136px;
            height: 44px;
            margin: 0 1rem;
            padding: 13px 0;
          }

          .proceed-payment {
            width: 150px;
          }

          p {
            @include details-light();
            font-weight: 300;
            margin: 0 1rem;
          }
        }
      }

      .splits {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 16px;

        span {
          color: $accent;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .divider {
      border-bottom: 1px solid #e0e0e0;
      margin: 0 32px;
    }

    .chat {
      flex: 1 1 auto;
      overflow-y: auto;

      &::-webkit-scrollbar {
        /* width of the entire scrollbar */
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        /* color of the tracking area */
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        /* color of the scroll thumb */
        background-color: $accent;
        /* roundness of the scroll thumb */
        border-radius: 5px;
      }

      padding: 0 32px;

      .messages {
        padding-bottom: 24px;

        .sending-message {
          opacity: 0.5;
        }

        .item {
          .date {
            margin-top: 32px;
            color: $text-additional;
            text-align: center;
            @include date-time-light();
          }

          .system-message {
            margin-top: 32px;
            text-align: center;
            color: $text-additional;

            .date {
              @include date-time-light();
            }

            .description {
              @include body-light();

              &.left {
                text-align: left;
              }

              .name {
                color: $accent;
              }
            }
          }

          .message {
            display: flex;
            margin-top: 32px;

            .left {
              .image {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 50%;

                img {
                  object-fit: cover;
                }
              }
            }

            .middle {
              margin-top: -3px;
              margin-left: 16px;
              margin-right: 22px;
              flex: 1;

              .name {
                font-size: 16px;
                line-height: 16px;
                color: $text;
              }

              .text {
                @include body-light();
                color: $bg-dark;
                margin-top: 8px;

                .shared-file {
                  font-size: 0.95rem;
                }
              }

              .track {
                margin-top: 5px;
                width: 75%;
              }
            }

            .right {
              .time {
                color: $text-additional;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: -0.165px;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .input {
        margin-top: auto;
        width: 100%;
        padding: 0 32px;
        /* padding-bottom: 60px; */
        padding-bottom: 20px;

        .texta {
          height: 148px;
          border-top: 1px solid #e0e0e0;
          display: flex;
          flex-direction: column;

          textarea {
            height: 109px;
            margin: 2px;
            padding-top: 15px;
            border: none;
            background: transparent;
            resize: none;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text-additional;
            cursor: auto;

            &:focus {
              outline: none;
            }

            &::-webkit-scrollbar {
              /* width of the entire scrollbar */
              width: 6px;
            }

            &::-webkit-scrollbar-track {
              /* color of the tracking area */
              @include background-opacity(#cdcccc, 0.3);
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
              /* color of the scroll thumb */
              background-color: $accent;
              /* roundness of the scroll thumb */
              border-radius: 5px;
            }
          }

          .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
              &:focus {
                outline: none;
              }
            }

            button {
              &:hover {
                cursor: pointer;
              }
            }

            .attachment {
              width: 38px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: none;
              border: none;
              border-radius: 50%;
              margin-right: 24px;
            }

            .upload-active {
              background-color: #ececec;
            }

            .send {
              width: 38px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              background: $accent;
              border-radius: 50%;

              &:disabled {
                background: $text-additional;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }
}
</style>

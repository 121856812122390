var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.$apollo.queries.results.loading)?_c('Loader',{attrs:{"color":"#FF035A","size":"50"}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-panel"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Search.svg")}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],attrs:{"type":"search","placeholder":"Search by song name"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})]),_c('CustomSelect',{attrs:{"default":"Sort: A to Z","options":[
            {
              value: 'az',
              label: 'Sort: A to Z'
            },
            {
              value: 'za',
              label: 'Sort: Z to A'
            }
          ]},on:{"setValue":function (value) { return (_vm.sortBy = value); }}})],1),_c('div',{staticClass:"songs-container"},[_c('div',{staticClass:"cards-wrapper"},[_c('div',{staticClass:"cards"},_vm._l((_vm.songsByCategory),function(ref,i){
          var title = ref.title;
          var songs = ref.songs;
          var text_cleared = ref.text_cleared;
return _c('div',{key:i,staticClass:"category"},[_c('div',{staticClass:"category-title"},[_vm._v(_vm._s(title))]),(!songs.length)?_c('div',{staticClass:"category-cleared"},[_vm._v(" "+_vm._s(text_cleared)+" ")]):_vm._e(),_vm._l((songs),function(ref){
          var id = ref.id;
          var name = ref.name;
          var collaborators = ref.collaborators;
          var isHost = ref.isHost;
          var myRPM = ref.myRPM;
return _c('div',{key:id,staticClass:"card",class:{
                  'selected-card': id == _vm.active,
                  'action-needed': title == 'ACTION NEEDED'
                },on:{"click":function($event){return _vm.selectSong(id)}}},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"party-icon"},[_vm._v(_vm._s(isHost ? "H" : "G"))])]),_c('div',{staticClass:"collaborators"},_vm._l((collaborators),function(ref,i){
                var info = ref.info;
return _c('div',{key:i,staticClass:"collaborator",attrs:{"title":info.name}},[(info.avatar_url)?_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":info.avatar_url}}):_vm._e()])}),0),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"my-rpm"},[_c('span',[_vm._v(" My Split: "),_c('strong',[_vm._v(_vm._s(myRPM || "100% Rec, 100% Pub"))])])])])})],2)}),0)])])]),_c('SongPreview',{attrs:{"songs":_vm.songs},on:{"refetchSongs":function($event){return _vm.$apollo.queries.results.refetch()}}}),(_vm.showModal)?_c('AddSong',{attrs:{"my-artist":_vm.myArtist,"add-new-required":""},on:{"close":function($event){_vm.showModal = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract_question.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <ContractQuestion v-model="question" />
              <div class="buttons">
                <button
                  :disabled="isInvalid || submitting"
                  @click="updateTerms"
                  class="primary"
                >
                  {{ submitting ? "Submitting..." : "Update" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ContractQuestion from "@/components/DealTerm.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_CONTRACT_QUESTIONS from "@/api/queries/GET_CONTRACT_QUESTIONS.gql";
import UPDATE_CONTRACT_TERMS from "@/api/mutations/UPDATE_CONTRACT_TERMS.gql";

export default {
  name: "UpdateContractTerm",
  components: {
    ModalCloseIcon,
    Loader,
    ContractQuestion
  },
  data() {
    return {
      contract_id: null,
      question: {},
      question_ids: [],
      submitting: false
    };
  },
  props: {
    deal: Object,
    updateQuestion: String
  },
  watch: {
    contract_question(question) {
      this.question = question;
    }
  },
  computed: {
    isInvalid() {
      const { isAnswerInvalid, question } = this;
      return !isAnswerInvalid(question);
    }
  },
  methods: {
    formatQuestion(questions) {
      let question_ids = [];

      const addSubs = question => {
        question_ids.push(question.id);
        if (!question.choices) return { ...question, answer: {} };

        const choices = question.choices.map(choice => {
          let sub;
          if (choice.sub_id) {
            sub = questions.find(el => el.id === choice.sub_id);
            if (sub) sub = { ...addSubs(sub), answer: {} };
          }
          return { ...choice, sub };
        });

        return { ...question, choices, answer: {} };
      };

      const question = questions.find(
        question => question.id === this.updateQuestion
      );

      const formatted = addSubs(question);
      this.question_ids = question_ids;

      return formatted;
    },

    isAnswerInvalid({ answer = {}, type }) {
      const { isAnswerInvalid } = this;
      if (
        ["null", "undefined"].includes(typeof answer.value) ||
        (type !== "multiple_choice" && !answer.value)
      )
        return false;
      if (answer.sub && !isAnswerInvalid(answer.sub)) return false;
      return true;
    },

    parseAnswers() {
      const { question, question_ids, contract_id } = this;

      const getAnswers = ({ id, answer }) => {
        let response = [{ question_id: id, answer: { value: answer.value } }];
        if (contract_id) Object.assign(response[0], { contract_id });
        if (answer.sub) response.push.apply(response, getAnswers(answer.sub));
        return response;
      };

      const updated = getAnswers(question);
      const deleted = {
        _or: question_ids.flatMap(id => {
          return !updated.find(el => el.question_id === id)
            ? [
                {
                  contract_id: { _eq: contract_id },
                  question_id: { _eq: id }
                }
              ]
            : [];
        })
      };

      return { updated, deleted };
    },

    updateTerms() {
      this.submitting = true;

      const {
        parseAnswers,
        deal: {
          id: deal_id,
          status,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const { updated, deleted } = parseAnswers();

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} made changes to their terms`,
          sender: "You made changes to your terms"
        }
      };

      if (status.includes("sign")) {
        message.system_message.recipient = message.system_message.recipient.concat(
          ". You need re-review and sign the contract"
        );
      }

      this.$apollo.mutate({
        mutation: UPDATE_CONTRACT_TERMS,
        variables: { deal_id, updated, deleted, message },
        update: (
          store,
          { data: { insert_contract_answers, delete_contract_answers } }
        ) => {
          if (insert_contract_answers && delete_contract_answers) {
            this.submitting = false;
            this.$emit("close");
          }
        }
      });
    }
  },
  apollo: {
    contract_question: {
      query: GET_CONTRACT_QUESTIONS,
      variables() {
        const {
          deal: { id: deal_id }
        } = this;
        return { deal_id };
      },
      update({ contracts, contract_questions }) {
        if (contracts.length) this.contract_id = contracts[0].id;
        return this.formatQuestion(contract_questions);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
.modal-container {
  width: 916px;
  height: 719px;
  // height: fit-content;
  // background: #ffffff;
  // box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  // border-radius: 15px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.modal-body {
  padding: 10px 80px;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);
  &::-webkit-scrollbar {
    /* width of the entire scrollbar */
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    /* color of the tracking area */
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    background-color: $accent;
    /* roundness of the scroll thumb */
    border-radius: 5px;
  }
  .buttons {
    height: 5rem;
    padding: 1.5rem 0;
    display: flex;
    justify-content: flex-end;
    border-top: solid #f0f0f0 0.5px;
    button {
      margin: 0 5px;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>

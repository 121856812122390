<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h3>Payment status</h3>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="body-off-platform">
                <div class="">
                  <DealTerm :key="question.id" v-model="question" />
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="buttons">
                <button
                  :disabled="question.answer.value == undefined"
                  class="primary"
                  @click="submit"
                >
                  Submit
                </button>
                <button @click="$emit('close')" class="primary outline">
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import DealTerm from "@/components/DealTerm.vue";
import UPDATE_SONG from "@/api/mutations/UPDATE_SONG.gql";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";

export default {
  components: {
    ModalCloseIcon,
    DealTerm
  },
  props: {
    deal: Object
  },
  data() {
    return {
      question: {
        id: "6c31c155-c6d2-490b-9125-34fb856a8724",
        title: "Has the payment been done off platform?",
        type: "multiple_choice",
        placeholder: null,
        parent_id: null,
        conditions: { party: "guest" },
        comment: null,
        choices: [
          {
            label: "Yes",
            value: true
          },
          { label: "No", value: false }
        ],
        append: null,
        prepend: null,
        answer: {}
      }
    };
  },
  mounted() {},
  methods: {
    submit() {
      const id = this.deal.song_id;
      const update = {
        payment_done_off_platform: this.question.answer.value
      };
      this.$apollo.mutate({
        mutation: UPDATE_SONG,
        variables: { id, update },
        update: (store, { data: { update_songs_by_pk } }) => {
          console.log({ update_songs_by_pk });
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const songIndex = data.songs.findIndex(song => song.id === id);
          if (songIndex > -1) {
            data.songs[songIndex] = {
              ...data.songs[songIndex],
              ...update_songs_by_pk
            };
          }

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          // Emit close event
          this.$emit("close", true);
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
.modal-container {
  width: 816px;
  height: 40vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    display: flex;
    width: 100%;
    justify-content: center;
    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;
      img {
        object-fit: cover;
      }
    }
    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;
      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(40vh - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  padding-top: 0;

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;
    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
.modal-footer {
  padding: 7px 40px 20px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;

    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>

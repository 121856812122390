<template>
  <div class="container">
    <input
      type="file"
      id="track-file"
      @change="onFileChange"
      v-show="false"
      :accept="accept ? `${accept}/*` : '*'"
    />
    <label
      for="track-file"
      class="upload cursor-pointer"
      @drop.prevent="handleDroppedFile"
      @dragover.prevent
      v-if="!file.name && !uploadComplete"
    >
      <img src="../assets/icons/Upload-Cloud2.svg" />
      <span>Drag your {{ name || "file" }} here or click to upload</span>
    </label>
    <div class="upload" style="padding:16px" v-else-if="file.name">
      <div v-if="!uploadProgress">
        <span><b>Starting the upload...</b></span>
        <Loader size="30" />
      </div>
      <div v-else-if="!uploadComplete" class="uploading">
        <span>
          Uploading <b>{{ file.name }}</b>
          ...
        </span>
        <div class="progress" v-if="uploadProgress > 0">
          <b>{{ uploadProgress - 1 }}%</b>
          <progress max="100" :value="uploadProgress" />
        </div>
      </div>
      <div v-else-if="!value">
        <span><b>Saving the file...</b></span>
        <Loader size="30" />
      </div>
      <div v-else class="uploaded">
        <span>
          <b>{{ file.name }}</b> Uploaded Successfully
        </span>
        <div class="check">
          <img src="../assets/icons/Check.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

import S3_UPLOAD from "@/api/mutations/S3_UPLOAD.gql";
import SAVE_FILE from "@/api/mutations/SAVE_FILE.gql";

export default {
  name: "FileUpload",
  components: {
    Loader
  },
  props: {
    value: String,
    accept: String,
    name: String
  },
  data() {
    return {
      file: {},
      uploadUrl: "",
      fileKey: "",
      uploadProgress: 0,
      uploadComplete: false
    };
  },
  watch: {
    uploadUrl: function(url) {
      if (this.file.name && url) {
        this.uploadFile(url, this.file);
      }
    }
  },
  methods: {
    onFileChange(e) {
      this.addFile(e.target.files);
    },
    handleDroppedFile({ dataTransfer }) {
      this.addFile(dataTransfer.files);
    },
    addFile(files) {
      if (!files) return;
      const file = files[0];
      if (this.accept && file.type.split("/")[0] !== this.accept) {
        return alert(`The file has to be of type "${this.accept}"`);
      }
      if (file.size > 15000000) {
        return alert("The file can't be larger than 15MBs");
      }
      this.file = file;
      this.getUploadUrl();
    },
    getUploadUrl() {
      const { name, type } = this.file;
      const o = name.split(".");
      const extension = `.${o[o.length - 1]}`;

      this.$apollo.mutate({
        mutation: S3_UPLOAD,
        variables: { extension, type },
        update: (_, { data: { s3_upload } }) => {
          (this.uploadUrl = s3_upload.url), (this.fileKey = s3_upload.key);
        }
      });
    },
    uploadFile(url, file) {
      let _this = this;
      return new Promise(function(resolve, reject) {
        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              _this.uploadComplete = true;
              _this.saveFile();
              resolve(xhr);
            } else {
              reject(xhr);
            }
          }
        };

        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            var percentComplete = (e.loaded / file.size) * 100;
            _this.uploadProgress = Math.round(percentComplete);
          }
        };

        xhr.open("PUT", url);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.send(file);
      });
    },
    saveFile() {
      if (this.fileKey && this.uploadComplete) {
        this.$apollo.mutate({
          mutation: SAVE_FILE,
          variables: {
            key: this.fileKey,
            name: this.file.name,
            type: this.file.type.split("/")[0]
          },
          update: (_, { data: { insert_files_one } }) => {
            this.$emit("input", insert_files_one.id);
          }
        });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.container {
  padding-bottom: 32px;
}

.upload {
  width: 100%;
  height: 103px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  span {
    font-size: 14px;
  }
}

.uploading {
  width: 100%;
  text-align: center;
  .progress {
    width: 50%;
    margin-left: 25%;
    progress[value] {
      appearance: none;
      border: none;
      width: 100%;
      height: 15px;
      background-color: $gray;
      border-radius: 10px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
      color: $accent;
      position: relative;
      margin: 0;
    }

    progress[value]::-webkit-progress-bar {
      background-color: $gray-6;
      border-radius: 10px;
    }

    progress[value]::-webkit-progress-value {
      position: relative;
      background-color: $accent;
      border-radius: 10px;
      -webkit-animation: animate-stripes 5s linear infinite;
      animation: animate-stripes 5s linear infinite;
    }

    span {
      position: absolute;
      left: 30%;
    }
  }
}

.uploaded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .check {
    width: 35px;
    height: 35px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7cc576;
    overflow: hidden;
    border-radius: 50%;
    img {
      object-fit: cover;
      width: 20px;
    }
  }
}

.uploading,
.uploaded {
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
}
</style>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h3>Contract Signing</h3>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="terms">
                <div class="text" v-html="deal.contract.generated_text" />
              </div>
              <div class="details">
                <div class="name">
                  <span>Full Name:</span>
                  <input
                    type="text"
                    v-model="name"
                    class="text-input"
                    placeholder="Enter your full name"
                  />
                </div>
                <div class="font">
                  <span>Change Font:</span>
                  <CustomSelect
                    :options="fonts"
                    default="Srisakdi"
                    @setValue="value => (font = value)"
                    class="font-selector"
                    noMargin
                  />
                </div>
              </div>
              <div class="signature">
                <div class="output">
                  <span class="name" :style="signatureStyle">
                    {{ name }}
                  </span>
                </div>
              </div>
              <span class="info">
                By selecting Accept and Sign, I agree that the signature will be
                the electronic representation of my signature for all purposes
                when I (or my agent) use them on documents, including legally
                binding contracts - just the same as a pen-and-paper signature
                or initial.
              </span>
              <div class="buttons">
                <button @click="$emit('close')" class="primary outline">
                  Close
                </button>
                <button
                  class="primary"
                  :disabled="!name || submitting"
                  @click="signContract"
                >
                  {{ submitting ? "Submitting..." : "Accept and Sign" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import CustomSelect from "@/components/Select.vue";

import GET_DEAL from "@/api/queries/GET_DEAL.gql";
import SIGN_CONTRACT from "@/api/mutations/SIGN_CONTRACT.gql";

export default {
  name: "ContractSigning",
  components: {
    ModalCloseIcon,
    CustomSelect
  },
  props: {
    deal: Object
  },
  data() {
    return {
      name: "",
      font: "Srisakdi",
      fonts: [
        { value: "Srisakdi", label: "Srisakdi" },
        { value: "Sacramento", label: "Sacramento" }
      ],
      submitting: false
    };
  },
  computed: {
    signatureStyle() {
      const { font } = this;
      return {
        fontFamily: `${font}, cursive`
      };
    }
  },
  methods: {
    signContract() {
      this.submitting = true;

      const {
        name,
        font,
        deal: {
          status: oldStatus,
          contract: { id },
          id: deal_id,
          party,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} has signed the contract`,
          sender: "You have signed the contract"
        }
      };

      const signed_contract = { [`${party}_signature`]: { name, font } };

      const status = oldStatus.includes("sign-pending")
        ? "contract-ready"
        : `contract-${party}-signed`;

      this.$apollo.mutate({
        mutation: SIGN_CONTRACT,
        variables: {
          id,
          signed_contract,
          deal_id,
          status,
          message
        },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_DEAL,
            variables: {
              id: this.$route.query.view
            }
          });

          data.deals_by_pk = {
            ...data.deals_by_pk,
            ...update_deals_by_pk
          };

          this.submitting = false;

          store.writeQuery({
            query: GET_DEAL,
            data
          });
          this.$emit("close");
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Srisakdi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

.modal-container {
  width: 816px;
  height: 95vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    display: flex;
    width: 100%;
    justify-content: center;
    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;
      img {
        object-fit: cover;
      }
    }
    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;
      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(100% - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  padding-top: 0;
  .terms {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }
    .text {
      padding: 0 40px;
    }
  }
  .details {
    width: 616px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .name {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
      input {
        height: 40px;
        width: 13rem;
        font-size: 14px;
        color: #3b3b3b;
      }
    }
    .font {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
      .font-selector {
        width: 11rem;
      }
    }
  }
  .signature {
    height: 8rem;
    width: 616px;
    background: $gray;
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .output {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-bottom: 1px solid $text-additional;
      margin: 0 24px;
      margin-bottom: 16px;
      .name {
        font-size: 45px;
        line-height: 72px;
        text-align: center;
        font-style: italic;
        letter-spacing: -0.22px;
        color: $text;
      }
    }
  }
  .info {
    @include details-light();
    width: 616px;
    margin-bottom: 1.5rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;
    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>

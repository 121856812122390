<template>
  <svg
    class="container"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ accent: accent, white: white, hovered: hovered }"
      d="M12.0515 0.961882C11.3459 0.257498 10.2032 0.257498 9.49761 0.961882L8.85831 1.60469L2.05298 8.40639L2.03852 8.42096C2.03502 8.42447 2.03502 8.42819 2.03129 8.42819C2.02406 8.43904 2.01321 8.44978 2.00609 8.46062C2.00609 8.46424 2.00236 8.46424 2.00236 8.46785C1.99513 8.4787 1.99163 8.48593 1.98429 8.49678C1.98078 8.5004 1.98078 8.5039 1.97717 8.50763C1.97355 8.51847 1.96994 8.52571 1.96621 8.53655C1.96621 8.54006 1.96271 8.54006 1.96271 8.54378L0.452809 13.0842C0.408516 13.2135 0.442188 13.3566 0.539473 13.4526C0.607832 13.52 0.700033 13.5577 0.795962 13.5574C0.83517 13.5567 0.874038 13.5506 0.911551 13.5393L5.44847 12.0258C5.45197 12.0258 5.45197 12.0258 5.4557 12.0223C5.46712 12.0189 5.47808 12.0141 5.48813 12.0077C5.49096 12.0074 5.49344 12.0062 5.49548 12.0042C5.50621 11.997 5.52067 11.9897 5.53152 11.9824C5.54225 11.9753 5.55321 11.9645 5.56406 11.9572C5.56768 11.9535 5.57118 11.9535 5.57118 11.95C5.57491 11.9464 5.58214 11.9429 5.58576 11.9355L13.0304 4.49092C13.7348 3.78529 13.7348 2.64262 13.0304 1.9371L12.0515 0.961882ZM5.33288 11.1769L2.81884 8.66299L9.11118 2.37065L11.6252 4.88458L5.33288 11.1769ZM2.46472 9.33122L4.66104 11.5274L1.36306 12.6255L2.46472 9.33122ZM12.5211 3.98517L12.1382 4.37172L9.62405 1.85756L10.0107 1.47113C10.4336 1.04855 11.1191 1.04855 11.5422 1.47113L12.5246 2.45359C12.9444 2.87843 12.9428 3.56236 12.5211 3.98517Z"
      fill="#3B3B3B"
    />
  </svg>
</template>

<script>
export default {
  name: "FlagIcon",
  props: {
    accent: Boolean,
    white: Boolean
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.container {
  cursor: pointer;
  .accent,
  .hovered {
    fill: $accent;
  }
  .white {
    fill: $white;
  }
}
</style>

<template>
  <transition name="modal" v-if="!addingCard">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.paymentMethods.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <div class="avatar">
                  <img src="../../assets/IngoSchimpff.jpg" />
                </div>
                <h2>Project Funding</h2>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="price">
                <h3 class="text">Price</h3>
                <span class="number">${{ deal.fee.toLocaleString() }}</span>
              </div>
              <p class="info">
                Your funds are held securely by Nvoko until project completion.
                Once you have received and reviewed the work, you will have the
                opportunity to release funds. If you fail to respond to a
                submission within 7 days, then funds are automatically released.
              </p>
              <div class="cards">
                <div class="title">
                  <span class="saved-text">Saved Cards</span>
                  <div @click="addingCard = true" class="add-card">
                    <div class="plus-icon">
                      <img src="../../assets/icons/Plus-Accent.svg" />
                    </div>
                    Add Card
                  </div>
                </div>
                <div class="cards-list">
                  <div
                    v-for="pm in paymentMethods"
                    :key="pm.id"
                    @click="selectPaymentMethod(pm.id)"
                    class="card-container"
                  >
                    <Card
                      :card="pm.card"
                      :selectedCard="selectedCard"
                      :pmId="pm.id"
                    />
                  </div>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="buttons">
                <button class="primary outline" @click="$emit('close')">
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="deposit"
                  :disabled="!selectedCard || submitting.status"
                >
                  {{ submitting.buttonText }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <AddCard v-else @close="addingCard = false" />
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Card from "@/components/Card.vue";
import Loader from "@/components/Loader.vue";
import AddCard from "@/components/modals/AddCard.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_PAYMENT_METHODS from "@/api/queries/GET_PAYMENT_METHODS.gql";
import FUND_DEAL from "@/api/mutations/FUND_DEAL.gql";

export default {
  name: "ProjectFunding",
  components: {
    ModalCloseIcon,
    Card,
    Loader,
    AddCard
  },
  props: {
    deal: Object
  },
  data() {
    return {
      selectedCard: null,
      submitting: {
        status: false,
        buttonText: "Deposit Funds"
      },
      addingCard: false
    };
  },
  methods: {
    selectPaymentMethod(index) {
      if (this.submitting.status) return;
      this.selectedCard = this.selectedCard === index ? null : index;
    },
    deposit() {
      this.submitting = {
        status: true,
        buttonText: "Submitting..."
      };

      const {
        selectedCard: card,
        deal: { id: deal }
      } = this;

      this.$apollo.mutate({
        mutation: FUND_DEAL,
        variables: { card, deal },
        update: (store, { data: { stripe_place_hold } }) => {
          if (!stripe_place_hold.success) return;
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === this.deal.song_id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === this.deal.id
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            fee_status: "requires_capture"
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.$emit("close");
        }
      });
    }
  },
  apollo: {
    paymentMethods: {
      query: GET_PAYMENT_METHODS,
      update: ({ stripe_get_payment_methods }) => stripe_get_payment_methods
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
.modal-container {
  width: 568px;
  height: fit-content;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    display: flex;
    padding: 0 8px;
    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;
      img {
        object-fit: cover;
      }
    }
    h2 {
      display: flex;
      align-items: center;
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;
    }
  }
}

.modal-body {
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  .price {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
    .text {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.666667px;
      color: $text;
    }
    .number {
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.07px;
      color: $accent;
    }
  }
  .info {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.666667px;
    color: $text;
  }
  .cards {
    margin-top: 32px;
    .title {
      display: flex;
      align-items: center;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: -0.22px;
      color: black;
      .saved-text {
        flex: 1;
      }
      .add-card {
        display: flex;
        align-items: center;
        cursor: pointer;
        .plus-icon {
          margin-right: 4px;
        }
      }
    }
    .cards-list {
      display: flex;
      margin-top: 8px;
      padding-bottom: 5px;
      overflow-x: scroll;
      scrollbar-width: thin;
      scrollbar-color: $accent rgba(#cdcccc, 0.3);
      &::-webkit-scrollbar {
        /* height of the entire scrollbar */
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        /* color of the tracking area */
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        /* color of the scroll thumb */
        background-color: $accent;
        /* roundness of the scroll thumb */
        border-radius: 5px;
      }
      .card-container {
        margin-right: 16px;
      }
    }
  }
}

.modal-footer {
  padding-bottom: 40px;
  .buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 40px;
    button {
      width: 182px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>

<template>
  <div v-if="$apollo.queries.url.loading" class="track-loading">
    <Loader size="20" />
  </div>
  <div class="player" v-else>
    <div class="left">
      <img src="../assets/icons/Track-Note.svg" />
      <span class="name">{{ file.name }}</span>
    </div>
    <div class="middle">
      <div class="control-buttons">
        <div v-if="buffering" class="loader" @click="togglePlay()">
          <Loader color="#FFFFFF" size="20" />
        </div>
        <img
          v-else-if="playing"
          @click="togglePlay()"
          src="../assets/icons/Track-Pause.svg"
        />
        <img
          v-else
          @click="togglePlay()"
          src="../assets/icons/Track-Play.svg"
        />
      </div>
      <div class="progress">
        <div class="time">
          <span>{{ currentTime | duration }}</span>
          <span v-if="audioDuration">{{ audioDuration | duration }}</span>
          <span v-else>--:--</span>
        </div>
        <input
          min="0"
          max="100"
          type="range"
          step="0.0001"
          :value="trackProgress"
          :style="progressStyleVars"
          @change="seek"
          @input="seek"
        />
      </div>
    </div>
    <div class="right" v-if="!download">
      <img src="../assets/icons/Track-Spotify.svg" />
      <span class="plays">10,267</span>
    </div>
    <div class="right-download" v-if="download">
      <a :href="url" download>
        <img src="../assets/icons/Track-Download.svg" />
      </a>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

import GET_FILE_URL from "@/api/queries/GET_FILE_URL.gql";

export default {
  name: "AudioPlayer",
  components: {
    Loader
  },
  props: {
    download: Boolean,
    file: Object
  },
  data() {
    return {
      audio: null,
      playing: false,
      paused: false,
      loadedMetadata: false,
      buffering: false,
      seeking: false,
      currentTime: 0,
      trackProgress: 0
    };
  },
  watch: {
    url(value) {
      this.audio = new Audio(value);
      this.audio.onloadedmetadata = () => {
        this.loadedMetadata = true;
      };
    }
  },
  computed: {
    progressStyleVars() {
      return {
        "--track-progress": `${this.trackProgress}%`
      };
    },
    audioDuration() {
      return this.loadedMetadata ? this.audio.duration : 0;
    }
  },
  filters: {
    duration(seconds) {
      const m = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0"),
        s = Math.floor(seconds % 60)
          .toString()
          .padStart(2, "0");

      return m + ":" + s;
    }
  },
  methods: {
    togglePlay() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },
    play() {
      this.playing = true;
      if (this.paused) {
        this.paused = false;
        return this.audio.play();
      }
      this.audio.ontimeupdate = () => {
        this.currentTime = this.audio.currentTime;
        this.trackProgress = (this.currentTime / this.audioDuration) * 100;
      };
      this.audio.onwaiting = () => {
        this.buffering = true;
      };
      this.audio.onplaying = () => {
        this.buffering = false;
      };
      this.audio.onended = () => {
        this.playing = false;
        this.paused = false;
      };
      this.audio.play();
    },
    pause() {
      this.playing = false;
      this.paused = true;
      this.buffering = false;
      this.audio.pause();
    },
    seek({ target: { value } }) {
      this.audio.currentTime = (value * this.audioDuration) / 100;
    }
  },
  apollo: {
    url: {
      query: GET_FILE_URL,
      variables() {
        return {
          id: this.file.id
        };
      },
      update: ({ s3_get_file_url: { url } }) => url
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles/_mixins.scss";

.track-loading {
  height: 30px;
  width: 30px;
}
.player {
  display: flex;
  padding: 0 20px;
  background: #232323;
  border-radius: 7px;
  color: $white;
  font-size: 14px;
  font-weight: 300;
  .left {
    display: flex;
    justify-content: center;
    padding: 13px 0;
    max-width: 30%;
    .name {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin-left: 14px;
      margin-right: 14px;
    }
  }
  .middle {
    flex: 4;
    display: flex;
    padding: 13px 0;
    .control-buttons {
      img {
        &:hover {
          cursor: pointer;
        }
      }
      .loader {
        width: 20px;
        height: 20px;
      }
    }
    .progress {
      flex: 1;
      margin-left: 17px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $white;
      .time {
        display: flex;
        justify-content: space-between;
        span {
          position: inherit;
          font-size: 12px;
          line-height: 10px;
        }
      }
      input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
        &:focus {
          outline: none;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          cursor: pointer;
          background-color: $accent;
          height: 10px;
          width: 10px;
          margin-top: -3.5px;
          border-radius: 50%;
          &:focus,
          &:hover {
            opacity: 0.9;
          }
        }
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 3px;
          cursor: pointer;
          background-image: linear-gradient(
            to right,
            $accent var(--track-progress),
            $text-additional var(--track-progress)
          );
        }
      }
      progress[value] {
        appearance: none;
        border: none;
        width: 100%;
        height: 4px;
        background-color: $text-additional;
        border-radius: 10px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
        color: $accent;
        position: relative;
        margin: 0;
      }

      progress[value]::-webkit-progress-bar {
        background-color: $text-additional;
        border-radius: 10px;
      }

      progress[value]::-webkit-progress-value {
        position: relative;
        background-color: $accent;
        border-radius: 10px;
        -webkit-animation: animate-stripes 5s linear infinite;
        animation: animate-stripes 5s linear infinite;
      }

      span {
        position: absolute;
        left: 30%;
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    padding: 13px 0;
    border-left: 1px solid #959696;
    img {
      margin-left: 24px;
      margin-right: 8px;
    }
  }
  .right-download {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    img {
      margin-left: 24px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>

<template>
    <div class="container p-10">
        <template v-if="spotifyId">
            <template v-if="$apollo.queries.catalog.loading">
                <Loader size="50" color="#FF035A" />
            </template>
            <template v-else>
                <template v-if="catalog.length > 0">
                    <div class="search border-2 border-gray-100 rounded-full px-4 py-2 max-w-sm">
                        <div class="icon">
                            <img src="../assets/icons/Search.svg" />
                        </div>
                        <input type="search" v-model="searchQuery" placeholder="Search by name" />
                    </div>

                    <div class="w-full border-2 rounded-2xl border-gray-100 flex flex-col p-10 gap-6 my-10 relative">
                        <div class="grid grid-cols-4 gap-4 w-full border-b border-gray-100 sticky">
                            <div class="col-span-2">
                                <div class="text-sm font-medium text-gray-500">Title</div>
                            </div>
                            <div class="">
                                <div class="text-sm font-medium text-gray-500">Album</div>
                            </div>
                            <div class="">
                                <div class="text-sm font-medium text-gray-500">Status</div>
                            </div>
                        </div>

                        <div class="flex flex-col gap-4">
                            <div class="grid grid-cols-4 gap-4" v-for="track in filteredCatalog" :key="track.id">
                                <div class="col-span-2 flex items-center justify-start gap-5">
                                    <img class="w-24 h-24 rounded-lg object-cover" :src="track.album.images[1]" />
                                    <div class="flex flex-col gap-2 flex-1">
                                        <div class="text-sm font-medium text-gray-800">
                                            {{ track.name }}
                                        </div>
                                        <div class="text-sm font-medium text-gray-400">
                                            {{ track.artists.map(artist => artist.name).join(", ") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center justify-start">
                                    <div class="text-sm font-medium text-gray-400">
                                        {{ track.album.name }}
                                    </div>
                                </div>
                                <div class="flex items-center justify-start">
                                    <div v-if="track.contracts.length > 0"
                                        class="flex text-xs gap-1 font-semibold rounded-lg py-2 px-3 items-center justify-center cursor-default select-none"
                                        style="background-color: #F5FFE4; color: #7AA62D;">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                                                stroke="#7AA62D" stroke-width="1.66667" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path
                                                d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                                                stroke="#7AA62D" stroke-width="1.66667" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                        Protected
                                    </div>
                                    <div v-else
                                        class="flex text-xs gap-1 font-semibold rounded-lg py-2 px-3 items-center justify-center cursor-default select-none"
                                        style="background-color: #FFEAE8; color: #D35A4D;">
                                        Not Protected
                                    </div>
                                </div>
                            </div>
                            <div v-if="filteredCatalog.length === 0">
                                <div class="text-sm font-medium text-gray-400">
                                    No tracks found
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-xl font-medium text-gray-800">Catalog</div>
                    <div class="text-sm font-medium text-gray-400 mt-3">
                        You don't have any tracks in your catalog yet, you can import them
                        from your spotify account.
                        <button class="primary ml-10" :disabled="importLoading" @click="importCatalog">
                            {{ importLoading ? "Importing..." : "Import" }}
                        </button>
                    </div>
                    <div v-if="importLoading" class="text-sm font-medium text-nvoko mt-3">
                        Importing your tracks may take some time, please be patient.
                    </div>
                </template>
            </template>
        </template>
        <template v-else>
            <div class="text-sm font-medium text-gray-500">
                You need to asociate a spotify account to view your catalog
            </div>
        </template>
    </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import GET_ARTIST_CATALOG from "@/api/queries/GET_ARTIST_CATALOG.gql";
import IMPORT_CATALOG from "@/api/mutations/IMPORT_CATALOG.gql";

export default {
    components: {
        Loader
    },
    data() {
        return {
            initialLoad: false,
            importLoading: false,
            searchQuery: "",
            spotifyId: this.$store.getters["account/getArtist"].spotify_id
        };
    },
    apollo: {
        catalog: {
            query: GET_ARTIST_CATALOG,
            variables() {
                return {
                    artistId: this.$store.getters["account/getArtist"].spotify_id
                };
            },
            update(data) {
                return data.imported_tracks;
            }
        }
    },
    methods: {
        async importCatalog() {
            this.importLoading = true;
            await this.$apollo.mutate({
                mutation: IMPORT_CATALOG,
                variables: {
                    artistId: this.spotifyId
                }
            });
            this.importLoading = false;
            this.$apollo.queries.catalog.refetch();
        }
    },
    computed: {
        filteredCatalog() {
            if (!this.searchQuery) {
                return this.catalog;
            }
            return this.catalog.filter(track => {
                const searchQueryLower = this.searchQuery.toLowerCase();
                return (
                    track.name.toLowerCase().includes(searchQueryLower) ||
                    track.artists.some(artist =>
                        artist.name.toLowerCase().includes(searchQueryLower)
                    ) ||
                    track.album.name.toLowerCase().includes(searchQueryLower)
                );
            });
        }
    },
    watch: {
        results(newVal) {
            console.log(newVal);
        }
    }
};
</script>

<template>
  <svg
    width="15"
    height="24"
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ accent: accent }"
      d="M0.665249 3.67791L9.50424 12.0112L0.665249 20.3445C0.454339 20.5433 0.287037 20.7794 0.172893 21.0392C0.0587496 21.299 -9.40957e-07 21.5774 -9.55472e-07 21.8586C-9.69986e-07 22.1399 0.0587495 22.4183 0.172893 22.6781C0.287037 22.9379 0.454339 23.174 0.665249 23.3728C0.876158 23.5717 1.12654 23.7294 1.40211 23.837C1.67768 23.9446 1.97303 24 2.2713 24C2.56957 24 2.86492 23.9446 3.14049 23.837C3.41606 23.7294 3.66644 23.5717 3.87735 23.3728L14.3338 13.5146C14.545 13.3159 14.7125 13.0799 14.8268 12.8201C14.9412 12.5603 15 12.2817 15 12.0005C15 11.7192 14.9412 11.4406 14.8268 11.1808C14.7125 10.921 14.545 10.685 14.3338 10.4863L3.87736 0.628097C3.6666 0.428993 3.41626 0.271031 3.14068 0.163253C2.86509 0.055476 2.56966 1.12323e-07 2.2713 9.92818e-08C1.97294 8.62401e-08 1.67752 0.055476 1.40193 0.163253C1.12634 0.271031 0.876004 0.428993 0.665249 0.628097C-0.200425 1.46572 -0.223205 2.84028 0.665249 3.67791Z"
      fill="#828282"
    />
  </svg>
</template>

<script>
export default {
  props: {
    accent: Boolean
  },
  computed: {},
  name: "AudioPlayer"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.accent {
  fill: $accent;
}
</style>

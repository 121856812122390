<template>
  <div v-if="$apollo.queries.url.loading" class="loading">
    <Loader size="20" />
  </div>
  <div class="link" v-else>
    <a :href="url" target="_blank" download>
      <img src="../assets/icons/Attach-File.svg" />
      <span>{{ file.name }}</span>
    </a>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

import GET_FILE_URL from "@/api/queries/GET_FILE_URL.gql";

export default {
  name: "FileLink",
  components: {
    Loader
  },
  props: {
    file: Object
  },
  apollo: {
    url: {
      query: GET_FILE_URL,
      variables() {
        return {
          id: this.file.id
        };
      },
      update: ({ s3_get_file_url: { url } }) => url
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  height: 30px;
  width: 30px;
}
.link {
  a {
    display: flex;
    width: 75%;
    align-items: center;
    box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
    border-radius: 5px;
    border: 1px solid #dddddd;
    text-decoration: none;
    color: $accent;
    padding: 0.2rem;
    img {
      height: 1.5rem;
      width: 1.5rem;
      transform: rotate(135deg);
    }
    span {
      margin-bottom: 2px;
      margin-left: 5px;
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>

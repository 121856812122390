var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.$apollo.queries.deals.loading)?_c('Loader',{attrs:{"size":"50"}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-panel"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Search.svg")}})]),_c('input',{attrs:{"type":"search","name":"search","id":"search","placeholder":"Search everything"}})]),_c('CustomSelect',{attrs:{"options":[
            { value: 'ff', label: 'Sent' },
            { value: 'ff', label: 'Received' }
          ],"default":"Sent and Received","noMargin":""}})],1),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab-nav"},[_c('div',{staticClass:"nav active"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"text"},[_vm._v("Offers")])]),_c('span',{staticClass:"underline"})]),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"text"},[_vm._v("In Progress")]),_c('div',{staticClass:"badge"},[_c('span',[_vm._v("1")])])]),_c('span',{staticClass:"underline"})]),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"text"},[_vm._v("Completed")])]),_c('span',{staticClass:"underline"})])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"cards-wrapper"},[_c('div',{staticClass:"cards"},_vm._l((_vm.deals),function(ref,i){
          var id = ref.id;
          var avatarUrl = ref.avatarUrl;
          var name = ref.name;
          var track = ref.track;
          var message = ref.message;
          var createdAt = ref.createdAt;
return _c('div',{key:i,staticClass:"card",class:{ 'selected-card': id === _vm.active },on:{"click":function($event){return _vm.openDeal(id)}}},[(avatarUrl)?_c('div',{staticClass:"card-image"},[_c('img',{attrs:{"src":avatarUrl}})]):_c('div',{staticClass:"card-initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(name))+" ")]),_c('div',{staticClass:"card-details"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(name))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(createdAt))])]),_c('div',{staticClass:"subtitle"},[_c('span',[_vm._v(_vm._s(track))])]),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(message))])])])])}),0)])])])]),_c('DealPreview')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :style="{ width: width + 'px', height: height + 'px', color: color }">
    <component
      :is="svgComponent"
      :width="width"
      :height="height"
      :viewBox="`0 0 ${width} ${height}`"
    />
  </div>
</template>

<script>
// Import your SVG files
import plus from "@/assets/icons/Plus.svg?inline";
import pen from "@/assets/icons/PenNew.svg?inline";
import check from "@/assets/icons/CheckNew.svg?inline";
import sign from "@/assets/icons/Sign.svg?inline";
import clock from "@/assets/icons/Clock.svg?inline";
import cash from "@/assets/icons/Cash.svg?inline";

export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: "24" // Ensure default is a string to match SVG attributes
    },
    height: {
      type: [String, Number],
      default: "24" // Ensure default is a string to match SVG attributes
    },
    color: {
      type: String,
      default: "currentColor"
    }
  },
  computed: {
    svgComponent() {
      const icons = {
        plus,
        pen,
        check,
        sign,
        clock,
        cash
      };
      return icons[this.name] || null;
    }
  }
};
</script>

<template>
  <div class="answers">
    <div v-for="({ question, answer }, i) in answers" :key="i" class="answer">
      <div class="question-title">
        <span class="text">{{ question.title }}</span>
        <div
          @click="$emit('update', question.id)"
          class="icon"
          v-if="canUpdate"
        >
          <PenIcon :accent="false" />
        </div>
      </div>
      <div class="answer-value">
        <p>{{ answer }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PenIcon from "@/assets/icons/Pen.vue";

export default {
  name: "ContractReviewTerms",
  components: {
    PenIcon
  },
  props: {
    answers: Array,
    canUpdate: Boolean
  }
};
</script>

<style scoped lang="scss">
.answers {
  .answer {
    margin-bottom: 32px;
    .question-title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: $text;
      }
      .icon {
        margin-left: 8px;
        display: flex;
        align-items: center;
      }
    }
    .answer-value {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.22px;
      color: $text;
    }
  }
}
</style>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <Loader v-if="$apollo.queries.deals.loading" color="#FF035A" size="50" />
      <div v-else class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div class="logo">
              <img src="../../assets/logo.png" alt="Nvoko Logo" />
            </div>
            <div @click="$emit('close')">
              <ModalCloseIcon :dark="true" />
            </div>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body">
            <h4>ALL COLLABORATORS</h4>
            <div class="content">
              <div class="collaborators">
                <div class="people">
                  <div
                    class="row"
                    v-for="(data, i) in validCollaborators"
                    :key="i"
                  >
                    <div class="initials">
                      {{ data.name | initials }}
                    </div>
                    <div class="details">
                      <span>{{ data.name }}</span>
                      <span class="email">{{ data.email }}</span>
                    </div>
                    <div class="percentage">
                      <input
                        min="0"
                        max="100"
                        step="0.01"
                        tabindex="0"
                        type="number"
                        placeholder="0.00"
                        v-model="data.percentage"
                        :class="{ invalid: data.percentage > 100 }"
                      />
                      %
                    </div>
                  </div>
                  <div class="row add" @click="openModal">
                    <button class="add-button">+</button>
                    <span>Add Another Collaborator</span>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <div class="percentage">
              <span class="info">Total</span>
              <span class="remaining">
                ({{
                  percentageError ? "-" : (100 - totalPercentage).toFixed(2)
                }}% Remaining)
              </span>
              <span class="total">
                {{ totalPercentage }}%
                <span class="error" v-if="percentageError">&#x26A0;</span>
              </span>
            </div>
            <div class="buttons">
              <button
                class="primary"
                :disabled="!splitsValid"
                @click="proposeSplit"
              >
                Propose
              </button>
            </div>
          </slot>
        </div>
      </div>
      <AddCollaborator
        v-if="addingCollaborator"
        v-model="collaborators[collaborators.length - 1]"
        @close="closeModal"
        :valid="validateCollaborators()"
      />
    </div>
  </transition>
</template>

<script>
import gql from "graphql-tag";
import Loader from "@/components/Loader.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import AddCollaborator from "@/components/modals/AddCollaboratorOld.vue";

const GET_DEALS = gql`
  query deals($song_id: uuid!) {
    deals(where: { song_id: { _eq: $song_id } }) {
      id
      artistFrom {
        id
        name
      }
      artistTo {
        id
        name
      }
    }
  }
`;

const CREATE_SPLIT = gql`
  mutation createSplit($split: splits_insert_input!) {
    insert_splits_one(object: $split) {
      status
    }
  }
`;

export default {
  name: "Splits",
  components: {
    Loader,
    ModalCloseIcon,
    AddCollaborator
  },
  props: {
    deal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      collaborators: [],
      addingCollaborator: false
    };
  },
  watch: {
    deals(val) {
      this.collaborators = val.map(
        ({ id: deal_id, artistTo: { id, name } }) => ({
          id,
          name,
          deal_id
        })
      );

      const host = val[0].artistFrom;

      this.collaborators.unshift({
        deal_id: this.deal.id,
        id: host.id,
        name: host.name
      });
    }
  },
  computed: {
    validCollaborators() {
      return this.collaborators.filter(el => el.name);
    },
    totalPercentage() {
      return this.collaborators.reduce(
        (a, { percentage }) => a + parseFloat(percentage || 0),
        0
      );
    },
    percentageError() {
      return this.totalPercentage > 100;
    },
    splitsValid() {
      return (
        this.collaborators.every(el => el.percentage > 0) &&
        this.totalPercentage === 100
      );
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    validateCollaborators() {
      const { collaborators } = this;
      const existing = collaborators.flatMap(({ id }) => (id ? [id] : []));
      const invited = collaborators.flatMap(el => (el.email ? [el.email] : []));
      if (new Set(existing).size < existing.length) return false;
      if (new Set(invited).size < invited.length) return false;
      return collaborators.every(({ id, name, user = {} }) => {
        if (id && name) return true;
        if (user.first_name && user.last_name && user.email && name)
          return true;
        return false;
      });
    },
    openModal() {
      this.collaborators.push({});
      this.addingCollaborator = true;
    },
    closeModal(val) {
      if (val === "cancelled") this.collaborators.pop();
      this.addingCollaborator = false;
    },
    formatSplit() {
      const {
        collaborators,
        deal: {
          info,
          party,
          myArtist: { id: proposer },
          artist: { id: otherParty }
        }
      } = this;

      const host = party === "host" ? proposer : otherParty;
      const split = { song_id: info.song_id, artist_id_proposer: proposer };

      const details = collaborators.map(
        ({ id, name, deal_id, percentage, user }) => {
          let resp = { percentage, accepted: id === proposer };

          if (deal_id) {
            resp.is_host = host === id;
            resp.deal_id = host === id ? null : deal_id;
          } else {
            resp.deal = { data: { ...info, artist_id_from: host } };
            if (id) resp.deal.data.artist_id_to = id;
            else
              resp.deal.data.artistTo = {
                data: {
                  name,
                  artist_users: {
                    data: {
                      role: "artist",
                      access: "owner",
                      user: { data: user }
                    }
                  },
                  artist_preferences: { data: {} }
                }
              };
          }

          return resp;
        }
      );

      return {
        ...split,
        split_details: { data: details }
      };
    },
    proposeSplit() {
      const split = this.formatSplit();
      this.$apollo.mutate({
        mutation: CREATE_SPLIT,
        variables: { split },
        update: () => {
          this.$emit("close");
        }
      });
    }
  },
  apollo: {
    deals: {
      query: GET_DEALS,
      variables() {
        const {
          deal: {
            info: { song_id }
          }
        } = this;
        return { song_id };
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #181818;
}

.modal-header {
  padding: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .logo {
    width: 55px;
  }
}

.modal-body {
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    color: $white;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // width: 100%;
    padding: 0 2rem;
    height: calc(100vh - 265px - 5.2rem);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .collaborators {
      height: 100%;

      .people {
        color: $white;
        margin-bottom: 3rem;

        .row {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 38px;
            width: 38px;
            font-family: sans-serif;
            font-size: 15px;
            border-radius: 50%;
            background-color: $black;
            border: 1px solid $accent;
            color: $white;
            margin-right: 1rem;
          }

          .details {
            display: flex;
            flex-direction: column;
            width: 10rem;
            margin-right: 1rem;

            span {
              font-size: 0.9rem;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }

            .email {
              color: $text-additional;
            }
          }

          .percentage {
            display: flex;
            align-items: center;

            input {
              background: none;
              border: none;
              border-bottom: 2px $gray-1 solid;
              width: 50px;
              text-align: right;
              color: $white;
              margin-right: 0.1rem;

              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }

              &:active,
              &:focus {
                outline: none;
              }
            }

            .invalid {
              border-bottom-color: red;
            }
          }

          .add-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 38px;
            width: 38px;
            font-family: sans-serif;
            font-size: 20px;
            border-radius: 50%;
            border: 1px $accent dashed;
            background-color: transparent;
            color: $accent;
            margin-right: 1rem;

            &:hover {
              cursor: pointer;
              background: rgba($text, 0.3);
            }

            &:focus {
              outline: none;
            }
          }
        }

        .add {
          width: fit-content;
          margin-top: 1.5rem;

          &:hover {
            color: $accent;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  .percentage {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      color: $white;
      font-size: 1.5rem;
      margin-right: 0.3rem;
    }

    .remaining {
      margin-right: 3rem;
      color: $text-additional;
    }

    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1.5rem;

      .error {
        color: rgb(190, 0, 0);
        font-size: 1.3rem;
        margin: 0.2rem 0.3rem 0;
      }
    }
  }

  button {
    width: 136px;
    height: 44px;
    margin: 0 16px;
    font-weight: 300;
  }
}
</style>

<template>
  <svg
    width="18"
    height="7"
    viewBox="0 0 18 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6974 2.44211H1.1405C0.513227 2.44211 0 2.92004 0 3.50417C0 4.08831 0.513227 4.56624 1.1405 4.56624H13.6974V6.46734C13.6974 6.94527 14.3133 7.17892 14.6669 6.83906L17.8375 3.8759C18.0542 3.66348 18.0542 3.33424 17.8375 3.12183L14.6669 0.158667C14.3133 -0.181194 13.6974 0.063081 13.6974 0.53039V2.44211Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "FlagIcon",
  props: {
    accent: Boolean
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.accent,
.hovered {
  fill: $accent;
}
</style>

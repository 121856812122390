<template>
  <div v-if="$apollo.queries.url.loading" class="loading">
    <Loader size="20" />
  </div>
  <div class="image" v-else>
    <img :src="url" width="100%" height="100%" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

import GET_FILE_URL from "@/api/queries/GET_FILE_URL.gql";

export default {
  name: "ImagePreview",
  components: {
    Loader
  },
  props: {
    file: Object
  },
  apollo: {
    url: {
      query: GET_FILE_URL,
      variables() {
        return {
          id: this.file.id
        };
      },
      update: ({ s3_get_file_url: { url } }) => url
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  height: 30px;
  width: 30px;
}
.image {
  width: 75%;
}
</style>

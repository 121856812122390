<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <span class="progress">{{ progress }}</span>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="step" v-if="step === 'contribution'">
                <span class="question">
                  What is GUEST's contribution to this project?
                </span>
                <div class="inputs">
                  <div class="checkboxbuttons">
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="contribution"
                          type="checkbox"
                          value="songwriting"
                        />
                        <span class="text">Songwriting</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="contribution"
                          type="checkbox"
                          value="recording"
                        />
                        <span class="text"
                          >Recording <br />
                          Performance</span
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="contribution"
                          type="checkbox"
                          value="production"
                        />
                        <span class="text"
                          >Production <br />
                          Services</span
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div
                    class="step question2"
                    v-if="contribution.includes('production')"
                  >
                    <span class="question">
                      What is GUEST's contribution to the production?
                    </span>
                    <div class="checkboxbuttons">
                      <div class="checkbox-group">
                        <label class="checkbox">
                          <input
                            v-model="production"
                            type="checkbox"
                            value="raw-stems"
                          />
                          <span class="text">Raw stems</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="checkbox-group">
                        <label class="checkbox">
                          <input
                            v-model="production"
                            type="checkbox"
                            value="mixing"
                          />
                          <span class="text">Mixing</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="checkbox-group">
                        <label class="checkbox">
                          <input
                            v-model="production"
                            type="checkbox"
                            value="mastering"
                          />
                          <span class="text">Mastering</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="step" v-if="step === 'fee'">
                <span class="question">
                  Will GUEST charge a fee?
                </span>
                <div class="inputs">
                  <div class="radiobuttons">
                    <div class="radio-group">
                      <label class="radio">
                        <input v-model="wantsAFee" type="radio" :value="true" />
                        <span class="text">Yes</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="wantsAFee"
                          @click="fee = 0"
                          type="radio"
                          :value="false"
                        />
                        <span class="text">No</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="step question2" v-if="wantsAFee">
                    <span class="question">
                      How much will GUEST charge as a fee?
                    </span>
                    <div class="form-group">
                      <input
                        v-model="fee"
                        type="number"
                        step="0.01"
                        min="0"
                        class="text-input"
                        placeholder="Type the number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="step" v-if="step === 'recording-royalty'">
                <span class="question">
                  Will GUEST get a Recording Royalty?
                </span>
                <div class="inputs">
                  <div class="radiobuttons">
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="wantsARecordingRoyalty"
                          type="radio"
                          :value="true"
                        />
                        <span class="text">Yes</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="wantsARecordingRoyalty"
                          @click="recordingRoyalty = 0"
                          type="radio"
                          :value="false"
                        />
                        <span class="text">No</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="step question2" v-if="wantsARecordingRoyalty">
                    <span class="question">
                      What recording royalty will GUEST receive from the master?
                    </span>
                    <div class="flex-row">
                      <input
                        v-model="recordingRoyalty"
                        type="number"
                        class="text-input"
                        placeholder="Type the number"
                        min="0"
                        max="100"
                      />
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div class="info" v-if="wantsARecordingRoyalty">
                  <p>
                    If HOST is unsigned, you will receive
                    <b>{{ Number(recordingRoyalty) || "_" }}%</b>
                    of the total recording royalty net receipts, but if the
                    artist is signed to a label, you will receive
                    <b>{{ Number(recordingRoyalty) || "_" }}%</b>
                    of the Host Artist’s share of recording royalties net
                    receipts based off of their recording agreement.
                  </p>
                </div>
              </div>
              <div class="step" v-if="step === 'songwriting-royalty'">
                <span class="question">
                  Will GUEST get a Songwriting Royalty?
                </span>
                <div class="inputs">
                  <div class="radiobuttons">
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="wantsASongwritingRoyalty"
                          type="radio"
                          :value="true"
                        />
                        <span class="text">Yes</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="wantsASongwritingRoyalty"
                          @click="songwritingRoyalty = 0"
                          type="radio"
                          :value="false"
                        />
                        <span class="text">No</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="step question2" v-if="wantsASongwritingRoyalty">
                    <span class="question">
                      What's GUEST's songwriter percentage?
                    </span>
                    <div class="flex-row">
                      <input
                        v-model="songwritingRoyalty"
                        type="number"
                        class="text-input"
                        placeholder="Type the number"
                        min="0"
                        max="100"
                      />
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div class="info">
                  <p>
                    This is the percentage that you will receive from publishing
                    administrators, and is also your copyright ownership in the
                    composition.
                  </p>
                  <p>
                    By not wanting a songwriter percentage, you are assigning
                    ownership in the composition copyright to the Host Artist,
                    and waiving your songwriter credit and the right to receive
                    all publishing and songwriter royalties.
                  </p>
                </div>
              </div>
              <div class="buttons">
                <button
                  v-if="buttons.includes('previous')"
                  @click="changeStep('previous')"
                  class="primary outline"
                >
                  Previous
                </button>
                <button
                  v-if="buttons.includes('next')"
                  :disabled="stepInvalid"
                  @click="changeStep('next')"
                  class="primary"
                >
                  Next
                </button>
                <button
                  v-if="buttons.includes('finish')"
                  :disabled="stepInvalid || submitting"
                  @click="editOffer"
                  class="primary"
                >
                  {{ editOfferButtonText }}
                </button>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import GET_DEAL from "@/api/queries/GET_DEAL.gql";
import EDIT_OFFER from "@/api/mutations/EDIT_OFFER.gql";

export default {
  name: "OfferEditor",
  components: {
    ModalCloseIcon
  },
  data() {
    return {
      revising: false,
      submitting: false,
      step: "contribution",
      contribution: [],
      production: [],
      wantsAFee: false,
      fee: 0,
      wantsARecordingRoyalty: false,
      recordingRoyalty: 0,
      wantsASongwritingRoyalty: false,
      songwritingRoyalty: 0
    };
  },
  props: {
    deal: Object
  },
  computed: {
    steps() {
      const { contribution } = this;
      const contains = (array, els) => {
        return els.some(el => array.includes(el));
      };
      return [
        "contribution",
        "fee",
        ...(contains(["recording", "production"], contribution)
          ? ["recording-royalty"]
          : []),
        ...(contains(["songwriting", "production"], contribution)
          ? ["songwriting-royalty"]
          : [])
      ];
    },
    progress() {
      const { step, steps } = this;
      return `Step ${steps.indexOf(step) + 1}`;
    },
    buttons() {
      const arr = [];
      const { step, steps } = this;
      const index = steps.indexOf(step);
      const last = steps.length - 1;
      if (index > 0) arr.push("previous");
      if (index < last) arr.push("next");
      if (index === last) arr.push("finish");
      return arr;
    },
    stepInvalid() {
      const {
        step,
        contribution,
        production,
        wantsAFee,
        fee,
        wantsARecordingRoyalty,
        recordingRoyalty,
        wantsASongwritingRoyalty,
        songwritingRoyalty
      } = this;
      switch (step) {
        case "contribution":
          if (!contribution.length) return true;
          return contribution.includes("production") && !production.length;
        case "fee":
          return wantsAFee && !Number(fee);
        case "recording-royalty":
          return wantsARecordingRoyalty && !Number(recordingRoyalty);
        case "songwriting-royalty":
          return wantsASongwritingRoyalty && !Number(songwritingRoyalty);
        default:
          return true;
      }
    },
    editOfferButtonText() {
      const text = this.revising ? "Revise Offer" : "Make an Offer";
      return this.submitting ? "Submitting..." : text;
    }
  },
  mounted() {
    const {
      fee,
      recording_royalty_minimum,
      songwriting_royalty_minimum,
      services
    } = this.deal;

    if (!services || !Object.keys(services).length) {
      return (this.revising = false);
    }

    this.revising = true;

    let contribution = [];
    let production = [];
    for (const [key, o] of Object.entries(services)) {
      if (o.value) {
        contribution.push(key);
        if (key === "production") production = o.services;
      }
    }

    this.contribution = contribution;
    this.production = production;

    if (fee) {
      this.wantsAFee = true;
      this.fee = fee;
    }
    if (recording_royalty_minimum) {
      this.wantsARecordingRoyalty = true;
      this.recordingRoyalty = recording_royalty_minimum;
    }
    if (songwriting_royalty_minimum) {
      this.wantsASongwritingRoyalty = true;
      this.songwritingRoyalty = songwriting_royalty_minimum;
    }
  },
  methods: {
    changeStep(o) {
      const { step, steps } = this;
      const operand = o === "next" ? 1 : -1;
      this.step = steps[steps.indexOf(step) + operand];
    },
    editOffer() {
      this.submitting = true;
      const {
        revising,
        contribution,
        production,
        fee,
        recordingRoyalty,
        songwritingRoyalty,
        deal: {
          id,
          myArtist: { id: fromID, name: myArtistName },
          artist: { id: toID }
        }
      } = this;

      const services = {
        songwriting: {
          value: contribution.includes("songwriting")
        },
        recording: {
          value: contribution.includes("recording")
        },
        production: {
          value: contribution.includes("production"),
          services: production
        }
      };

      const offer_message = {
        recipient: "You have received an offer",
        sender: "You have sent an offer"
      };

      const revision_message = {
        recipient: `${myArtistName} has revised the offer`,
        sender: "You have revised the offer"
      };

      this.$apollo.mutate({
        mutation: EDIT_OFFER,
        variables: {
          id,
          status: "offer-sent",
          services,
          fee,
          recordingRoyalty,
          songwritingRoyalty,
          toID,
          fromID,
          system_message: revising ? revision_message : offer_message
        },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_DEAL,
            variables: {
              id: this.$route.query.view
            }
          });

          data.deals_by_pk = { ...data.deals_by_pk, ...update_deals_by_pk };

          this.submitting = false;

          store.writeQuery({
            query: GET_DEAL,
            data
          });
          this.$emit("close");
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
.modal-container {
  width: 916px;
  height: 719px;
  // height: fit-content;
  // background: #ffffff;
  // box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  // border-radius: 15px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .progress {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 8px;
    color: $accent;
  }
}

.modal-body {
  padding: 10px 80px;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 10px;

  .step {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }
    .question {
      width: 100%;
      height: fit-content;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -1.025px;
      color: $text;
      margin-bottom: 24px;
    }
    .question2 {
      overflow: visible;
      margin-top: 1rem;
      .flex-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span {
          margin-left: 0.5rem;
        }
      }
    }
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .checkboxbuttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .checkbox-group {
          .checkbox {
            width: 144px;
          }
        }
      }
      .form-group {
        width: 320px;
        input {
          font-weight: 300;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.22px;
          color: $text-additional;
        }
      }
    }
    .info {
      margin: 1rem 4rem;
      text-align: center;
      p {
        margin: 1rem 0;
        color: $text-additional;
        font-weight: 200;
        b {
          color: $text;
        }
      }
    }
  }
  .buttons {
    height: 5rem;
    padding: 1.5rem 0;
    display: flex;
    justify-content: flex-end;
    border-top: solid #f0f0f0 0.5px;
    button {
      margin: 0 5px;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>

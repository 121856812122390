<template>
  <div>
    <transition name="modal" v-if="!updateQuestion">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <Loader
            v-if="$apollo.queries.answers.loading"
            color="#FF035A"
            size="50"
          />
          <div v-else class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <div @click="$emit('close')" class="icon">
                  <ModalCloseIcon :accent="false" />
                </div>
                <div class="title">
                  <h2>Contract Terms</h2>
                </div>
                <div class="progress">
                  <div class="step" :class="{ active: step > 0 }">
                    <div class="number">
                      <span class="text">1</span>
                    </div>
                    <div class="text" :class="{ active: step === 1 }">
                      <span>Deal Summary</span>
                    </div>
                  </div>
                  <div class="separator">
                    <ChevronIcon :accent="step > 0" />
                  </div>
                  <div class="step" :class="{ active: step > 1 }">
                    <div class="number">
                      <span class="text">2</span>
                    </div>
                    <div class="text" :class="{ active: step === 2 }">
                      <span>{{ dealSummary.otherParty }} Answers</span>
                    </div>
                  </div>
                  <div class="separator">
                    <ChevronIcon :accent="step > 1" />
                  </div>
                  <div class="step" :class="{ active: step > 2 }">
                    <div class="number">
                      <span class="text">3</span>
                    </div>
                    <div class="text" :class="{ active: step === 3 }">
                      <span>Your Answers</span>
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <div class="step1" v-if="step === 1">
                  <div class="details">
                    <div class="detail">
                      <span class="title">Host</span>
                      <span>{{ dealSummary.hostName }}</span>
                    </div>
                    <div class="detail">
                      <span class="title">Guest</span>
                      <span>{{ dealSummary.guestName }}</span>
                    </div>
                    <div class="detail">
                      <span class="title">Contribution</span>
                      <span>{{ dealSummary.contribution }}</span>
                    </div>
                    <div class="detail">
                      <span class="title">Fee</span>
                      <span>{{ dealSummary.fee }}</span>
                    </div>
                    <div class="detail">
                      <span class="title">Recording royalty</span>
                      <span>{{ dealSummary.recording_royalty }}</span>
                    </div>
                    <div class="detail">
                      <span class="title">Songwriting royalty</span>
                      <span>{{ dealSummary.songwriting_royalty }}</span>
                    </div>
                  </div>
                </div>
                <div class="step2" v-if="step === 2">
                  <ContractReviewTerms
                    :answers="answers[dealSummary.otherParty.toLowerCase()]"
                  />
                </div>
                <div class="step3" v-if="step === 3">
                  <ContractReviewTerms
                    canUpdate
                    @update="id => (updateQuestion = id)"
                    :answers="answers[dealSummary.myParty.toLowerCase()]"
                  />
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="buttons">
                  <button v-if="step === 1" class="primary outline">
                    Close
                  </button>
                  <button v-else @click="step--" class="primary outline">
                    Back
                  </button>
                  <button
                    v-if="step === 3"
                    @click="$emit('close')"
                    class="primary"
                  >
                    Finish
                  </button>
                  <button v-else @click="step++" class="primary">
                    Next
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <UpdateContractTerm
      v-else
      :deal="deal"
      :update-question="updateQuestion"
      @close="closeUpdateModal"
    />
  </div>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import ChevronIcon from "@/assets/icons/Chevron-right.vue";
import ContractReviewTerms from "../ContractReviewTerms.vue";
import UpdateContractTerm from "./UpdateContractTerm.vue";
import Loader from "@/components/Loader.vue";

import FETCH_CONTRACT_ANSWERS from "@/api/queries/FETCH_CONTRACT_ANSWERS.gql";

export default {
  name: "ContractReview",
  components: {
    ModalCloseIcon,
    ChevronIcon,
    ContractReviewTerms,
    UpdateContractTerm,
    Loader
  },
  props: {
    deal: Object
  },
  data() {
    return {
      step: 1,
      updateQuestion: ""
    };
  },
  computed: {
    dealSummary() {
      const {
        party,
        myArtist,
        artist,
        services,
        fee,
        recording_royalty_minimum: recording_royalty,
        songwriting_royalty_minimum: songwriting_royalty
      } = this.deal;

      return {
        myParty: party === "host" ? "Host" : "Guest",
        otherParty: party === "host" ? "Guest" : "Host",
        hostName: party === "host" ? myArtist.name : artist.name,
        guestName: party === "guest" ? artist.name : myArtist.name,
        contribution: Object.keys(services).join(", "),
        fee: "$" + fee.toLocaleString(),
        recording_royalty: recording_royalty
          ? `At least ${recording_royalty} %`
          : "None",
        songwriting_royalty: songwriting_royalty
          ? `At least ${songwriting_royalty} %`
          : "None"
      };
    }
  },
  methods: {
    formatAnswer({ answer, contract_question }) {
      let resp = {};
      resp.question = {
        id: contract_question.id,
        title: contract_question.title
      };

      if (contract_question.type !== "multiple_choice") {
        return { ...resp, answer: answer.value };
      }

      const choice = contract_question.choices.find(
        choice => choice.value === answer.value
      );

      return { ...resp, answer: choice.label };
    },
    closeUpdateModal() {
      this.updateQuestion = "";
      this.$apollo.queries.answers.refetch();
    }
  },
  apollo: {
    answers: {
      query: FETCH_CONTRACT_ANSWERS,
      variables() {
        return { deal_id: this.deal.id };
      },
      update({ contract_answers }) {
        const { formatAnswer: format } = this;
        let answers = { host: [], guest: [] };

        contract_answers.forEach(answer => {
          const party = answer.contract_question.conditions.party;
          answers[party].push(format(answer));
        });

        return answers;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 755px;
  height: fit-content;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    display: flex;
    padding: 0 8px;
    width: 100%;
    justify-content: center;
    h2 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -1.025px;
      color: $text;
    }
  }
  .progress {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 58px;
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      &.active {
        .number {
          background: $accent;
          .text {
            color: $white;
          }
        }
        .text {
          font-weight: 500;
          color: $text;
          &.active {
            font-weight: 700;
          }
        }
      }
      .number {
        @include background-opacity($text-additional);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        .text {
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.22px;
          color: $text-additional;
        }
      }
      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: $text-additional;
      }
    }
    .separator {
      margin-top: 18px;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
}

.modal-body {
  padding: 0 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .step1 {
    .details {
      margin-top: 24px;
      margin-left: -24px;
      .detail {
        width: 100%;
        display: flex;
        span {
          width: 50%;
          color: $text-additional;
          padding: 0 6px;
          margin-bottom: 16px;
          text-align: left;
          white-space: nowrap;
          font-size: 14px;
          line-height: 14px;
        }
        .title {
          color: $text;
          text-align: right;
          font-weight: 500;
        }
      }
    }
  }
  .step2,
  .step3 {
    padding: 0 50px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
  }
}

.modal-footer {
  padding: 4vh 40px;
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract_questions.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <!-- <span class="progress">
                {{ `Step ${step}` }}
              </span> -->
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <template>
                <DealTerm
                  v-for="(el, index) in validQuestions"
                  :key="el.id"
                  v-model="validQuestions[index]"
                  :parties="parties"
                  @update:answer="() => updateCounter++"
                />
              </template>
              <div class="buttons">
                <button
                  :disabled="!valid || submitting"
                  @click="createDeal"
                  class="primary"
                >
                  {{ submitting ? "Submitting..." : "Create Deal" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DealTerm from "@/components/DealTerm.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_CONTRACT_QUESTIONS from "@/api/queries/GET_CONTRACT_QUESTIONS.gql";
import CREATE_CONTRACT from "@/api/mutations/CREATE_CONTRACT.gql";

export default {
  name: "CreateDeal",
  components: {
    ModalCloseIcon,
    Loader,
    DealTerm
  },
  data() {
    return {
      step: 1,
      questions: [],
      submitting: false,
      splitQuestionsBool: [
        "18919b87-bbfb-4647-bdc1-39f67f30d8ec",
        "0215a342-06f2-4a8e-9247-f04a775880de",
        "8bb2d9a8-f4ac-47d4-b9f6-49477645171f"
      ],
      splitQuestions: [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ],
      updateCounter: 0
    };
  },
  props: {
    deal: Object
  },
  watch: {
    contract_questions(questions) {
      this.questions = questions;
    },
    comparisonAnswers: {
      handler(answers, oldVal) {
        const { splitQuestions, splitQuestionsBool, questions } = this;
        splitQuestions.forEach((el, i) => {
          const val = answers[el] > 0;
          if (
            !["undefined", ""].includes(typeof answers[el]) &&
            answers[splitQuestionsBool[i]] !== val
          ) {
            const question = questions.find(
              el => el.id === splitQuestionsBool[i]
            );
            question.answer = question.choices.find(el => el.value === val);
          }
        });

        let changedQuestionId = this.findChangedQuestionId(answers, oldVal);
        if (changedQuestionId) {
          this.resetDependentAnswers(changedQuestionId);
        }
      },
      deep: true
    }
  },
  computed: {
    parties() {
      const { artist, myArtist, party } = this.deal;
      const isHost = party === "host";
      return {
        host: isHost ? myArtist.name : artist.name,
        guest: isHost ? artist.name : myArtist.name
      };
    },
    currentQuestion() {
      const { validQuestions, step } = this;
      return validQuestions.length ? validQuestions[step - 1] : {};
    },
    stepInvalid() {
      const { isStepValid, currentQuestion } = this;
      return !isStepValid(currentQuestion);
    },
    valid() {
      return this.validQuestions.every(el => this.isStepValid(el));
    },
    validQuestions() {
      const { questions, runChecks, splitQuestionsBool } = this;
      const q = questions.flatMap(el => {
        if (splitQuestionsBool.includes(el.id)) return [];
        return runChecks(el.conditions?.checks) ? [el] : [];
      });
      return q;
    },
    comparisonAnswers() {
      let answers = {};
      this.parseAnswers(true).forEach(({ question_id, answer }) => {
        answers[question_id] = answer.value;
      });

      return answers;
    }
  },
  methods: {
    resetDependentAnswers(changedQuestionId) {
      this.questions.forEach(question => {
        if (
          question.conditions?.checks?.some(
            check => check.question_id === changedQuestionId
          )
        ) {
          // Assuming a simple structure where resetting simply means setting the answer to null or an initial state
          // You might need a more complex reset logic based on your application's needs
          if (question.answer.value) {
            question.answer = {}; // Resetting the answer, adjust according to your data structure
          }
        }
      });
    },
    findChangedQuestionId(newVal, oldVal) {
      for (const questionId in newVal) {
        const newAnswer = newVal[questionId];
        const oldAnswer = oldVal[questionId];

        // Check if both are arrays and compare their contents
        if (Array.isArray(newAnswer) && Array.isArray(oldAnswer)) {
          if (
            newAnswer.length !== oldAnswer.length ||
            !newAnswer.every((val, index) => val === oldAnswer[index])
          ) {
            return questionId;
          }
        } else if (newAnswer !== oldAnswer) {
          // Direct comparison for non-array values
          return questionId;
        }
      }
      return null; // In case there's no change detected
    },
    runChecks(checks) {
      const { comparisonAnswers } = this;

      const compare = ({ question_id, value, _or, _and }) => {
        if (_or) return _or.some(el => compare(el));
        if (_and) return _and.every(el => compare(el));
        switch (Object.keys(value)[0]) {
          case "_eq":
            return comparisonAnswers[question_id] === value._eq;
          case "_gte":
            return comparisonAnswers[question_id] >= value._gte;
          case "_lte":
            return comparisonAnswers[question_id] <= value._lte;
          case "_gt":
            return comparisonAnswers[question_id] > value._gt;
          case "_lt":
            return comparisonAnswers[question_id] < value._lt;
          case "_neq":
            return comparisonAnswers[question_id] !== value._neq;
          case "_contains":
            return comparisonAnswers[question_id].includes(value._contains);
          default:
            return false;
        }
      };

      return checks ? checks.every(el => compare(el)) : true;
    },

    fillPartyNames(question) {
      const resp = question;
      const { host, guest } = this.parties;
      const hostFilled = question.title.replace(new RegExp("host", "ig"), host);
      resp.title = hostFilled.replace(new RegExp("guest", "ig"), guest);
      return resp;
    },

    formatQuestions(questions) {
      const { fillPartyNames } = this;
      let formatted = [];

      const addSubs = question => {
        if (!question.choices)
          return { ...fillPartyNames(question), answer: {} };

        const choices = question.choices.map(choice => {
          let sub;
          if (choice.sub_id) {
            sub = questions.find(el => el.id === choice.sub_id);
            if (sub) sub = { ...addSubs(sub), answer: {} };
          }
          return { ...choice, sub };
        });

        return {
          ...fillPartyNames(question),
          choices,
          answer: question.type === "multiple_choice_multiple" ? [] : {}
        };
      };

      questions.forEach(question => {
        if (!question.parent_id) formatted.push(addSubs(question));
      });

      return formatted;
    },

    isStepValid({ answer = {}, type, id }) {
      const { isStepValid, comparisonAnswers } = this;

      const splitQuestions = [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ];

      if (splitQuestions.includes(id) && Number(answer.value) > 100) {
        return false;
      } else if (
        id === "78b2ee02-5358-4df5-a1b2-3467b204ecc8" &&
        !answer.find(el => el.value === "producing")
      ) {
        return false;
      } else if (
        type === "multiple_choice_multiple" &&
        answer.length &&
        answer.every(el => !!el.value)
      ) {
        return true;
      } else if (
        ["null", "undefined"].includes(typeof answer.value) ||
        (type !== "multiple_choice" && !answer.value)
      ) {
        return false;
      } else if (
        id === "6c31c155-c6d2-490b-9125-34fb856a8724" &&
        answer.value &&
        !comparisonAnswers["5517025e-282e-4ead-bcad-224a65f9ca05"]
        // !comparisonAnswers["18919b87-bbfb-4647-bdc1-39f67f30d8ec"]
      ) {
        return false;
      } else if (answer.sub && !isStepValid(answer.sub)) {
        return false;
      }

      return true;
    },

    parseAnswers(isGetAll) {
      const { questions } = this;
      let answers = [];

      const getAnswers = ({ id, type, answer }) => {
        let response =
          isGetAll ||
          answer.length ||
          typeof answer.value === "boolean" ||
          typeof answer.value === "number" ||
          (answer.value || []).length
            ? [
                {
                  question_id: id,
                  answer: {
                    value:
                      type !== "multiple_choice_multiple"
                        ? answer.value
                        : answer.map(el => el.value)
                  }
                }
              ]
            : [];
        if (answer.sub) {
          const subAnswers = getAnswers(answer.sub);
          response = [...response, ...subAnswers];
        }
        return answers.find(answer => answer.question_id === id)
          ? []
          : response;
      };

      questions.forEach(question => {
        const questionAnswers = getAnswers(question);
        answers = [...answers, ...questionAnswers];
      });

      return answers;
    },

    calculateNewSplit(fromID, deal_id, song_id, split, answers) {
      const { splitQuestions } = this;
      let newSplit = split;

      let index = newSplit.findIndex(el => el.deal_id === deal_id);
      let newPercentage = newSplit[index].percentage;

      answers.forEach(({ question_id, answer: { value } }) => {
        if (splitQuestions.includes(question_id)) {
          newPercentage[
            ["recording", "publishing", "music_videos"][
              splitQuestions.findIndex(el => el === question_id)
            ]
          ] = Number(value);
        }
      });

      newSplit[index].percentage = newPercentage;

      return {
        song_id,
        artist_id_proposer: fromID,
        split_details: { data: newSplit }
      };
    },

    getDealUpdates(answers, party) {
      let resp = { status: `deal-updated-${party}` };
      const services = "58c27cc4-b0eb-4ab0-b1ad-2fd7b7ca1b1a";
      const involvesFee = "6c31c155-c6d2-490b-9125-34fb856a8724";
      const fee = "5517025e-282e-4ead-bcad-224a65f9ca05";
      answers.forEach(({ question_id, answer: { value } }) => {
        if (question_id === services) resp.services = value;
        if (question_id === fee) resp.fee = parseFloat(value);
        if (question_id === involvesFee) {
          if (value) resp.fee_status = "pending";
          else {
            resp.fee_status = null;
            resp.fee = 0;
          }
        }
      });
      return resp;
    },

    async createDeal() {
      this.submitting = true;

      const {
        parseAnswers,
        calculateNewSplit,
        getDealUpdates,
        deal: {
          party,
          split: oldSplit,
          song_id,
          id: deal_id,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const answers = parseAnswers();

      const split = calculateNewSplit(
        fromID,
        deal_id,
        song_id,
        oldSplit,
        answers
      );

      const dealUpdates = getDealUpdates(answers, party);

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} has answered contract questions`,
          sender: "You have answered contract questions"
        }
      };

      await this.$apollo.mutate({
        mutation: CREATE_CONTRACT,
        variables: { deal_id, answers, message, dealUpdates, split },
        update: async (store, { data: { insert_splits_one } }) => {
          const id = this.$store.getters["account/getArtistId"];

          let data = null;

          await this.trackContractHistory(song_id, fromID, "createdContract");

          await this.trackContractHistory(song_id, toID, "waitingForApproval");

          try {
            data = await store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: id
              }
            });
          } catch (error) {
            location.reload();
          }

          if (!data) return;

          const index = data.songs.findIndex(el => el.id === song_id);

          data.songs[index] = {
            ...data.songs[index],
            splits: [insert_splits_one]
          };

          await store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: id
            },
            data
          });

          this.submitting = false;

          this.$emit("close");
        }
      });

      this.submitting = false;
    }
  },
  apollo: {
    contract_questions: {
      query: GET_CONTRACT_QUESTIONS,
      variables() {
        const {
          deal: { id: deal_id }
        } = this;
        return { deal_id };
      },
      update({ contract_questions }) {
        return this.formatQuestions(contract_questions);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 916px;
  height: 719px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .progress {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 1px;
    color: $accent;
  }
}

.modal-body {
  padding: 10px 80px;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);

  &::-webkit-scrollbar {
    /* width of the entire scrollbar */
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    /* color of the tracking area */
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    background-color: $accent;
    /* roundness of the scroll thumb */
    border-radius: 5px;
  }

  .buttons {
    height: 5rem;
    /* padding: 1.5rem 0; */
    padding: 2rem 0 1rem;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: center;
    /* border-top: solid #f0f0f0 0.5px; */
    border-top: none;

    button {
      margin: 0 5px;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.answers.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h2>Deal Terms</h2>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="terms">
                <div class="answers">
                  <div
                    v-for="({ question, answer }, i) in answers"
                    :key="i"
                    class="answer"
                  >
                    <div class="question-title">
                      <span class="font-bold text">{{ question.title }}</span>
                    </div>
                    <div
                      class="file-link"
                      v-if="answer && answer.type === 'file'"
                    >
                      <FileLink :file="answer.file" />
                    </div>
                    <div v-else class="answer-value">
                      <p>{{ answer }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="buttons">
                <button @click="$emit('close')" class="primary">
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";
import FileLink from "@/components/FileLink.vue";

import FETCH_CONTRACT_ANSWERS from "@/api/queries/FETCH_CONTRACT_ANSWERS.gql";

export default {
  name: "ViewDealTerms",
  components: {
    ModalCloseIcon,
    Loader,
    FileLink
  },
  props: {
    deal: Object
  },
  data() {
    return {
      splitQuestionsBool: [
        "18919b87-bbfb-4647-bdc1-39f67f30d8ec",
        "0215a342-06f2-4a8e-9247-f04a775880de",
        "8bb2d9a8-f4ac-47d4-b9f6-49477645171f"
      ]
    };
  },
  computed: {
    parties() {
      const { artist, myArtist, party } = this.deal;
      const isHost = party === "host";
      return {
        host: isHost ? myArtist.name : artist.name,
        guest: isHost ? artist.name : myArtist.name
      };
    }
  },
  methods: {
    getAnswer(id) {
      return (this.answers.find(el => el.question.id === id) || {}).answer;
    },
    fillPartyNames(title) {
      const { host, guest } = this.parties;
      const hostFilled = title.replace(new RegExp("host", "ig"), host);
      return hostFilled.replace(new RegExp("guest", "ig"), guest);
    },
    formatAnswer({ answer, contract_question }) {
      let resp = {};

      if (this.splitQuestionsBool.includes(contract_question.id)) return [];

      resp.question = {
        id: contract_question.id,
        title: this.fillPartyNames(contract_question.title)
      };

      if (contract_question.type === "file") {
        return [
          {
            ...resp,
            answer: {
              type: "file",
              file: { id: answer.value, name: "Open file" }
            }
          }
        ];
      }

      if (contract_question.type === "multiple_choice") {
        const answerObject =
          contract_question.choices.find(
            choice => choice.value === answer.value
          ) || {};

        return [{ ...resp, answer: answerObject.label }];
      }

      if (contract_question.type === "multiple_choice_multiple") {
        return [
          {
            ...resp,
            answer: answer.value
              .map(el => el.charAt(0).toUpperCase() + el.slice(1))
              .join(", ")
          }
        ];
      }

      const { prepend, append } = contract_question;

      return [
        {
          ...resp,
          answer: (prepend || "") + answer.value + (append || "")
        }
      ];
    }
  },
  apollo: {
    answers: {
      query: FETCH_CONTRACT_ANSWERS,
      variables() {
        return { deal_id: this.deal.id };
      },
      update({ contract_answers }) {
        const { formatAnswer: format } = this;
        return contract_answers.flatMap(answer => format(answer));
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 755px;
  height: fit-content;
  max-height: calc(100vh - 40px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 16px;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title {
    display: flex;
    padding: 0 8px;
    width: 100%;
    justify-content: center;

    h2 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -1.025px;
      color: $text;
    }
  }
}

.modal-body {
  padding: 0 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .terms {
    margin: 20px 0;
    padding: 0 50px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .answers {
      .answer {
        margin-bottom: 32px;

        .question-title {
          margin-bottom: 8px;
          display: flex;
          align-items: center;

          .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .icon {
            margin-left: 8px;
            display: flex;
            align-items: center;
          }
        }

        .file-link {
          width: 65%;
        }

        .answer-value {
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
          letter-spacing: -0.22px;
          color: $text;
        }
      }
    }
  }
}

.modal-footer {
  padding: 7px 40px 20px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;

    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>
